import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	portal: (id: number) => `/portal/${id}`,
};

export const Translation = {
	pl: {
		IssueModelNew: {
			Hooks: {
				UseInitialLoad: {
					error: 'Brak zgłoszenia lub brak uprawnień do wyświetlenia zgłoszenia.',
				},
			},
		},
	},
	en: {
		IssueModelNew: {
			Hooks: {
				UseInitialLoad: {
					error: `You don't have permissions to this issue or there is no issue with this id.`,
				},
			},
		},
	},
};
