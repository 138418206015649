import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { EOpenIssue } from '@skillandchill/tasker-types';
import { errorVariant } from '@skillandchill/tasker-widgets2/dist/utils';

import { fetch_IssueModalComments, issue_fetchOneNew, issueModal_fetchBasic } from '@/store/actions';
import { Dispatch } from '@/store/types';
import { useQuery } from '@/utils/hooks/useQuery';
import { useTrans } from '@/utils/hooks/useTrans';

import { Props } from './model';
import { Resources } from './resources';

export const useInitialLoad = (props: Props) => {
	const { onError, onClose } = props;
	const query = useQuery();
	const dispatch: Dispatch = useDispatch();
	const snackbar = useSnackbar();
	const history = useHistory();
	const { t } = useTrans('IssueModelNew.Hooks.UseInitialLoad');

	const onErrorWrapper = () => {
		onError();
		snackbar.enqueueSnackbar(t('error'), errorVariant);
	};

	useEffect(() => {
		const issueId = query['issueId'];

		dispatch(issueModal_fetchBasic());

		if (issueId === undefined || issueId === null) onClose();
		if (!Number.isInteger(parseInt(issueId)) || Number.isNaN(parseInt(issueId)) || !parseInt(issueId)) return;
		// if (!Number.isInteger(parseInt(issueId)) || Number.isNaN(parseInt(issueId))) return;
		dispatch(issue_fetchOneNew(Number(issueId)))
			.catch(onErrorWrapper)
			.then(x => {
				if (x?.data?.OpenIssue === EOpenIssue.Portal) {
					const path = Resources.portal(Number(issueId));
					history.replace(path);
					onClose();
				}
			});
		dispatch(fetch_IssueModalComments(Number(issueId)));
	}, [query['issueId']]);
};
