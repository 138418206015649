import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

const address = process?.env?.REACT_APP_BACKEND_ADDRESS ?? '';

export const Resources = {
	urlDownload: (id: number) => `${process.env.REACT_APP_PROTOCOL}://${address}/Attachment/streamable/${id}`,
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueModalNew: {
			IssueModalContent: {
				IssueDetailsPage: {
					TabsContent: {
						Attachment: {
							deleteSuccess: 'Usunięto pomyślnie',
							uploadSuccess: 'Pomyślnie wrzucono pliki',
						},
					},
				},
			},
		},
	},
	en: {
		IssueModalNew: {
			IssueModalContent: {
				IssueDetailsPage: {
					TabsContent: {
						Attachment: {
							deleteSuccess: 'Delete success',
							uploadSuccess: 'Upload success',
						},
					},
				},
			},
		},
	},
};
