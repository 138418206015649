import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	showItemsInSelect: 3,
	tagPath: '/admin/tag/',
	components: 'components',
	selectArgs: {
		menuPosition: 'fixed',
		isClearable: true,
		isSearchable: false,
		closeMenuOnSelect: false,
	},

	...GlobalResources,
};
'Kanban.KanbanBoard.KanbanContent.EditKanbanContent.TabsContent.MappingsTab.';

export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					EditKanbanContent: {
						TabsContent: {
							MappingsTab: {
								InnerEditList: {
									KanbanFormSection: {
										KanbanIssueForm: {
											IssueFormSectionWithCollapse: {
												FieldDefinition: {
													MultiTag: {
														gotToTagGroup:
															'Przejdź do grupy tagów przypisanej do tego pola',
													},
												},
											},
										},
									},
								},
							},
						},
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					EditKanbanContent: {
						TabsContent: {
							MappingsTab: {
								InnerEditList: {
									KanbanFormSection: {
										KanbanIssueForm: {
											IssueFormSectionWithCollapse: {
												FieldDefinition: {
													MultiTag: {
														gotToTagGroup: 'Go to tag group assigned to this field ',
													},
												},
											},
										},
									},
								},
							},
						},
					},
				},
			},
		},
	},
};
