import { Translation as UseInitialLoad } from './hooks/UseInitialLoad/resources';
import { Translation as IssueModalFieldRequired } from './IssueModalActions/FieldsRequiredIndicator/resources';
import { Translation as IssueModalActions } from './IssueModalActions/resources';
import { Translation as IssueModalContentIssueDetailsPageBasicIssueData } from './IssueModalContent/IssueDetailsPage/MainIssueData/resources';
import { Translation as IssueModalContentIssueDetailsPageRightSideIssueDataExtraIssueInfo } from './IssueModalContent/IssueDetailsPage/RightSideIssueData/ExtraIssueInfo/resources';
import { Translation as IssueModalContentIssueDetailsPageRightSideIssueData } from './IssueModalContent/IssueDetailsPage/RightSideIssueData/resources';
import { Translation as IssueModalContentIssueDetailsPageTabs } from './IssueModalContent/IssueDetailsPage/Tabs/resources';
import { Translation as IssueModalContentIssueDetailsPageTabsContentAttachment } from './IssueModalContent/IssueDetailsPage/TabsContent/Attachment/resources';
import { Translation as IssueModalContent } from './IssueModalContent/resources';
import { Translation as IssueModalContentHistory } from './IssueModalContent/Worklogs/resources';
import { Translation as IssueModalMoreOptions } from './IssueModalTitle/MoreOptions/resources';
import { Translation as IssueModalSocialMediaForm } from './IssueModalTitle/MoreOptions/SocialMediaLinks/Form/resources';
import { Translation as IssueModalSocialMediaLinks } from './IssueModalTitle/MoreOptions/SocialMediaLinks/resources';
import { Translation as IssueModalSocialMediaLink } from './IssueModalTitle/MoreOptions/SocialMediaLinks/SocialMediaLink/resources';
import { Translation as IssueModalTitle } from './IssueModalTitle/resources';
import { Translation as IssueModalNew } from './resources';

export const IssueModalNewTranslationEN = [
	IssueModalActions.en,
	IssueModalContentIssueDetailsPageBasicIssueData.en,
	IssueModalContentIssueDetailsPageRightSideIssueDataExtraIssueInfo.en,
	IssueModalContentIssueDetailsPageRightSideIssueData.en,
	IssueModalContentIssueDetailsPageTabs.en,
	IssueModalTitle.en,
	IssueModalContent.en,
	IssueModalNew.en,
	UseInitialLoad.en,
	IssueModalContentHistory.en,
	IssueModalFieldRequired.en,
	IssueModalMoreOptions.en,
	IssueModalSocialMediaLinks.en,
	IssueModalSocialMediaLink.en,
	IssueModalSocialMediaForm.en,
	IssueModalContentIssueDetailsPageTabsContentAttachment.en,
];

export const IssueModalNewTranslationPL = [
	IssueModalActions.pl,
	IssueModalContentIssueDetailsPageBasicIssueData.pl,
	IssueModalContentIssueDetailsPageRightSideIssueDataExtraIssueInfo.pl,
	IssueModalContentIssueDetailsPageRightSideIssueData.pl,
	IssueModalContentIssueDetailsPageTabs.pl,
	IssueModalTitle.pl,
	IssueModalContent.pl,
	IssueModalNew.pl,
	UseInitialLoad.pl,
	IssueModalContentHistory.pl,
	IssueModalFieldRequired.pl,
	IssueModalMoreOptions.pl,
	IssueModalSocialMediaLinks.pl,
	IssueModalSocialMediaLink.pl,
	IssueModalSocialMediaForm.pl,
	IssueModalContentIssueDetailsPageTabsContentAttachment.pl,
];
