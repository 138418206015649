import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanCreator: {
					TagsCreation: {
						ClockConfiguration: {
							clockFinish: 'Konfiguracja zegarka. Gdzie ma trafić zadanie po zakończeniu zadania.',
							clockPause: 'Konfiguracja zegarka. Gdzie ma trafić zadanie po wstrzymaniu zadania.',
							clockStart: 'Konfiguracja zegarka. Gdzie ma trafić zadanie po rozpoczęciu zadania.',
						},
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanCreator: {
					TagsCreation: {
						ClockConfiguration: {
							clockFinish: 'Clock configuration. Place where should be task after end.',
							clockPause: 'Clock configuration.  Place where should be task after pause.',
							clockStart: 'Clock configuration. Place where should be task after Start.',
						},
					},
				},
			},
		},
	},
};
