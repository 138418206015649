import clsx from 'clsx';
import React from 'react';

import { VisibilityControl } from '@skillandchill/tasker-widgets2';

import { Attachment } from 'view/IssueModelNew/IssueModalContent/IssueDetailsPage/TabsContent/Attachment';

import { EIssueModalDetailsPageTabs } from '../Tabs/model';

import { IssueComments } from './IssueComments';
import { IssueDescription } from './IssueDescription';
import { Props } from './model';
import { useStyles } from './styles';

export const TabsContent = (props: Props) => {
	const { selectedTab } = props;
	const classes = useStyles();

	return (
		<div className={clsx(classes.flexGrow, classes.flexRow)}>
			<VisibilityControl condition={selectedTab === EIssueModalDetailsPageTabs.Description}>
				<IssueDescription />
			</VisibilityControl>
			<VisibilityControl condition={selectedTab === EIssueModalDetailsPageTabs.Comments}>
				<IssueComments />
			</VisibilityControl>
			<VisibilityControl condition={selectedTab === EIssueModalDetailsPageTabs.Attachments}>
				<Attachment />
			</VisibilityControl>
		</div>
	);
};
