import { t } from 'i18next';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	googleDrive: { name: 'googleDrive' },
	discord: { name: 'discord' },
	meet: { name: 'meet' },
	bgColor: 'background.paper',
	...GlobalResources,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			Integrations: {
				title: 'Integracje',
				description: 'Zarządzaj integracjami z narzędziami zewnętrznymi',
			},
			TabsContent: {
				Integration: {
					googleDrive: { label: 'Dysk google' },
					meet: { label: 'Meet' },
					discord: { label: 'Discord' },
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			Integrations: {
				title: 'Integrations',
				description: 'Manage integrations with external tools',
			},
			TabsContent: {
				Integration: {
					googleDrive: { label: 'Google drive' },
					meet: { label: 'Meet' },
					discord: { label: 'Discord' },
				},
			},
		},
	},
};

export const getTabs = () => {
	return [
		{ value: Resources.googleDrive.name, label: t('OrganizationPanel.TabsContent.Integration.googleDrive.label') },
		// { value: Resources.meet.name, label: t('OrganizationPanel.TabsContent.Integration.meet.label') },
		{ value: Resources.discord.name, label: t('OrganizationPanel.TabsContent.Integration.discord.label') },
	];
};
