import { DropResult } from 'react-beautiful-dnd';
import Cookies from 'universal-cookie';

import {
	EParameter,
	FullFilters,
	ICustomer,
	IFieldValue,
	IIssueForm,
	IKanban,
	IKanbanIssue,
	IKanbanIssueList,
	IKanbanSwimline,
	IProject,
	ITag,
	ITagGroup,
	ITagGroupContext,
	ITaskerUser,
} from '@skillandchill/tasker-types';
import { IIssueToSwimlineToOrder } from '@skillandchill/tasker-types/dist/types';
import { IMapTagToFieldDefinition } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/mapTagToTag';
import { IQuickFilter } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/quickfilter';
import { IKanbanTag } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/tag';
import { IFilterCustomer, IFilterSimplifiedCustomer } from '@skillandchill/tasker-types/dist/types/endPoints/filter';
import {
	IPortalFilterFieldDefinition,
	IPortalFilterForms,
	IPortalFilterTags,
} from '@skillandchill/tasker-types/dist/types/endPoints/portalFilter';

import * as actionTypes from '@/store/actions';
import { getDataCustomerFilters, getDataFromCustomerFiltersSimplify } from '@/store/reducers/utils/filterReducerUtils';
import { ObjectAction } from '@/store/types';

import { getInitialFilterNew } from 'view/SearchBar/resources';

function getKanbanCookie(): number {
	const cookies = new Cookies();
	const kanbanIdCookie = cookies.get('kanbanId');
	const kanbanIdCookieNumber = Number.parseInt(kanbanIdCookie);
	if (!Number.isNaN(kanbanIdCookieNumber)) {
		return kanbanIdCookieNumber;
	}
	return -1;
}

function getKanbanFiltersCookie(): FullFilters {
	const kanbanUserCookie = localStorage.getItem('kanbanFilter');
	if (!kanbanUserCookie) return getInitialFilterNew();
	const object: FullFilters = JSON.parse(kanbanUserCookie) as FullFilters;
	return object?.Issue ? object : getInitialFilterNew();
}

export interface KanbanTileDropData {
	issueToSwimlineToOrder: Partial<IIssueToSwimlineToOrder>[];
	dropResult: DropResult;
	attributeDefinitionId?: number;
	initialState: { [x: number]: IKanbanIssueList[] };
	initialStateCounters: { [x: number]: { [x: number]: number } };
	isSameColumn: boolean;
}

export const State = {
	///////////////Kanban//////////////////
	kanban: {} as IKanban,
	kanbanInViewportId: undefined as number | undefined,
	lastKanbanId: 0 as number,
	clockDidChange: 0,
	///////////////IssueTagsDroppableContent////////////////
	issueTypeTags: [] as ITag[],
	///////////////KanbanBoard KanbanHeader/////////
	projects: [] as IProject[], //Options
	kanbans: [] as IKanban[],
	///////////////KanbanBoard KanbanCreator/////////
	tagGroupContexts: [] as ITagGroupContext[],
	kanbanTagGroups: [] as ITagGroup[],

	filtersKanbanNew: getInitialFilterNew() as FullFilters,
	filterDidChange: 0 as number,
	filterIsApplied: 0 as number,

	projectsWithForms: [] as IProject[],
	issueIdsWaitingFor: [] as number[],
	kanbanForms: [] as IIssueForm[],
	kanbanSwimlines: [] as IKanbanSwimline[],
	swimlinesConfiguration: [] as IKanbanSwimline[],
	kanbanIssues: { 0: [] } as { [x: number]: IKanbanIssueList[] },
	issueUpdated: 0 as number,
	isCountUpdated: 0 as number,
	isKanbanCollapsed: true as boolean,
	swimlineCounts: {} as { [x: number]: { [x: number]: number } },
	kanbanFormMappings: [] as IMapTagToFieldDefinition[],
	kanbanEditMappings: [] as Partial<IMapTagToFieldDefinition>[],
	kanbanTileDropData: undefined as KanbanTileDropData | undefined,
	filter: getKanbanFiltersCookie() as FullFilters,
	isLiteMode: false as boolean,
	filters: {
		Options: {
			Project: [] as IProject[],
			Tag: [] as Partial<ITag>[] | { Label: string; Tags: number[] }[],
			Customer: [] as ICustomer[],
			IssueForm: [] as IIssueForm[],
		},
		customerFilters: [] as IFilterCustomer[],
		customerFiltersSimplified: [] as IFilterSimplifiedCustomer[],
		choosenQuickFilter: {} as IQuickFilter,
		projects: [] as IProject[],
		carouselUsers: [] as Partial<ITaskerUser>[],
		filter: getKanbanFiltersCookie() as FullFilters,
		Status: {
			personalizedForms: [] as IPortalFilterForms[],
			personalizedFieldDefinitions: [] as IPortalFilterFieldDefinition[],
			personalizedTags: [] as IPortalFilterTags[],
			personalizedProjects: [] as IProject[],
			choosenForms: [] as IPortalFilterForms[],
			choosenFieldDefinitions: [] as IPortalFilterFieldDefinition[],
			choosenProjects: [] as IProject[],
		},
		Tags: {
			personalizedForms: [] as IPortalFilterForms[],
			personalizedFieldDefinitions: [] as IPortalFilterFieldDefinition[],
			personalizedTags: [] as IPortalFilterTags[],
			personalizedProjects: [] as IProject[],
			choosenForms: [] as IPortalFilterForms[],
			choosenFieldDefinitions: [] as IPortalFilterFieldDefinition[],
			choosenProjects: [] as IProject[],
		},
		ExcludedTags: {
			personalizedForms: [] as IPortalFilterForms[],
			personalizedFieldDefinitions: [] as IPortalFilterFieldDefinition[],
			personalizedTags: [] as IPortalFilterTags[],
			personalizedProjects: [] as IProject[],
			choosenForms: [] as IPortalFilterForms[],
			choosenFieldDefinitions: [] as IPortalFilterFieldDefinition[],
			choosenProjects: [] as IProject[],
		},
	},
};
export type IKanbanState = typeof State;

const kanbanReducer = (state = State, action: ObjectAction): IKanbanState => {
	switch (action?.type) {
		case actionTypes.FETCH_ALL_KANBANS: {
			return {
				...state,
				kanbans: action?.kanbans,
			};
		}
		case actionTypes.DELETE_KANBAN: {
			return {
				...state,
				kanbans: action?.kanbans,
				kanban: {} as IKanban,
			};
		}
		case actionTypes.FETCH_ONE_KANBAN: {
			const newSwimelineCounters = state?.swimlineCounts;
			action?.kanban?.KanbanColumnWrapper?.Tag?.forEach((each: IKanbanTag) => {
				newSwimelineCounters[each?.Id] = {};
				each?.SwimlineCounters?.forEach(swimlineCounter => {
					(newSwimelineCounters[each?.Id] ?? {})[swimlineCounter?.SwimlineId] = swimlineCounter?.IssueCount;
				});
			});

			let kanbanId = state?.lastKanbanId;
			const cookies = new Cookies();
			if (action?.kanban?.Id) {
				cookies.set('kanbanId', action?.kanban?.Id, { path: '/' });
				kanbanId = action?.kanban?.Id;
			} else {
				const kanbanIdFromCookie = getKanbanCookie();
				if (kanbanIdFromCookie > 0) kanbanId = kanbanIdFromCookie;
			}
			const emptyKanbanIssues: { [x: number]: IKanbanIssueList[] } = { 0: [] };
			for (const swimline of action?.kanban?.KanbanSwimline ?? []) {
				emptyKanbanIssues[swimline?.Id] = [];
			}
			const counters: {
				[x: number]: {
					[x: number]: number;
				};
			} = {};
			action?.kanban?.KanbanSwimline?.forEach((each: Partial<IKanbanSwimline>) => {
				if (each.Id) counters[each?.Id] = (each?.IssueCounts ?? {})[each?.Id];
			});

			return {
				...state,
				isLiteMode: action?.kanban?.IsLiteMode,
				lastKanbanId: kanbanId,
				kanban: action?.kanban,
				kanbanSwimlines: action?.kanban?.KanbanSwimline,
				swimlineCounts: counters,
				issueIdsWaitingFor: [],
				kanbanIssues: {},
			};
		}
		case actionTypes.FETCH_ISSUE_TYPE_TAGS: {
			return {
				...state,
				issueTypeTags: action?.tags,
			};
		}
		case actionTypes.FETCH_ALL_PROJECTS: {
			return {
				...state,
				projects: action.data ?? [],
			};
		}
		case actionTypes.TAG_GROUP_CONTEXTS_FETCH: {
			return {
				...state,
				tagGroupContexts: action.contexts,
			};
		}
		case actionTypes.FETCH_TAG_GROUPS_BY_CONTEXT: {
			return {
				...state,
				kanbanTagGroups: action.groups,
			};
		}
		case actionTypes.SET_SWIMLINE_COLLAPSE: {
			return {
				...state,
				kanbanSwimlines: state?.kanbanSwimlines?.map(swimline => {
					if (swimline?.KanbanId == action?.kanbanId && swimline?.Id == action?.swimlineId)
						return { ...swimline, IsCollapsed: action?.value };
					else return swimline;
				}),
			};
		}

		case actionTypes.FETCH_SWIMLINES_WITH_CONFIGURATIONS: {
			const counters = state.swimlineCounts;
			action?.swimlines?.forEach((swimline: Partial<IKanbanSwimline>) => {
				if (swimline?.Id && !counters[swimline?.Id]) {
					counters[swimline?.Id] = {};
					state?.kanban?.KanbanColumnWrapper?.Tag?.forEach(each => {
						counters[swimline?.Id as number][each?.Id as number] = 0;
					});
				}
			});
			return {
				...state,
				kanbanSwimlines: action.swimlines?.filter((swimline: IKanbanSwimline) => swimline?.IsVisible),
				swimlinesConfiguration: action.swimlines,
				swimlineCounts: counters,
			};
		}
		case actionTypes.SET_ISSUE_IDS_WAITING_FOR: {
			const issueIds = state?.issueIdsWaitingFor;
			return {
				...state,
				issueIdsWaitingFor: action?.isAdd
					? [...issueIds, action?.data]
					: issueIds?.filter(filter => filter != action?.data),
			};
		}
		case actionTypes.SET_DROP_RESULT: {
			return {
				...state,
				kanbanTileDropData: action?.result,
			};
		}
		case actionTypes.ISSUE_ADD_NEW: {
			const newItems: { [x: number]: IKanbanIssueList[] } = state?.kanbanIssues;
			const newSwimlineCounts = state?.swimlineCounts;
			const defaultSwimlineId: number | undefined = state?.kanban?.KanbanSwimline?.find(
				swimline => swimline?.IsDefault
			)?.Id;

			if (defaultSwimlineId) {
				newItems[defaultSwimlineId] = newItems[defaultSwimlineId]?.map(item => {
					if (
						action?.issue?.IssueToSwimlineToOrder?.some(
							(some: IIssueToSwimlineToOrder) => some?.IssueKanbanTagId == item?.ColumnTagId
						)
					)
						return { ...item, Count: item?.Count + 1, Issues: [action.issue, ...item.Issues] };
					else return item;
				});

				newSwimlineCounts[defaultSwimlineId][action?.kanbanColumnId] =
					newSwimlineCounts[defaultSwimlineId][action?.kanbanColumnId] + 1;
			}
			return {
				...state,
				swimlineCounts: newSwimlineCounts,
				kanbanIssues: newItems,
				issueUpdated: state.issueUpdated + 1,
				isCountUpdated: state.isCountUpdated + 1,
			};
		}
		case actionTypes.SET_KANBAN_TAGS: {
			return {
				...state,
				kanban: {
					...state.kanban,
					KanbanColumnWrapper: { ...state?.kanban?.KanbanColumnWrapper, Tag: action?.data },
				},
			};
		}
		case actionTypes.FETCH_KANBAN_ISSUES: {
			let newItems = state?.kanbanIssues[action?.swimlineId];
			if (newItems?.length > 0 && action?.from > 0) {
				newItems = newItems?.map(kanbanList => {
					const thisColumnIssues =
						action?.data?.Data.find(
							(find: IKanbanIssueList) => find?.ColumnTagId == kanbanList?.ColumnTagId
						)?.Issues ?? [];
					return { ...kanbanList, Issues: [...kanbanList.Issues, ...thisColumnIssues] };
				}) as IKanbanIssueList[];
			} else newItems = action?.data?.Data;
			return {
				...state,
				kanbanIssues: {
					...state?.kanbanIssues,
					[action?.swimlineId]: newItems,
				},
			};
		}
		case actionTypes.SET_KANBAN_ISSUES: {
			return {
				...state,
				kanbanIssues: action?.data,
				issueUpdated: state?.issueUpdated + 1,
			};
		}
		case actionTypes.SET_KANBAN_SWIMLINE_ISSUE_COUNTS: {
			return {
				...state,
				swimlineCounts: action?.data,
				isCountUpdated: state.isCountUpdated + 1,
			};
		}
		case actionTypes.SET_KANBAN_COLLAPSE: {
			return {
				...state,
				isKanbanCollapsed: action?.state,
			};
		}
		case actionTypes.DELETE_ISSUE_FROM_KANBAN: {
			const newItems = state?.kanbanIssues;
			newItems[action?.swimlineId] = newItems[action?.swimlineId]?.map(column => {
				if (column?.ColumnTagId == action?.columnTagId)
					return { ...column, Issues: column?.Issues?.filter(issue => issue?.Id != action?.issueId) };
				else return column;
			});
			const counts = state.swimlineCounts;
			counts[action?.swimlineId][action?.columnTagId] = counts[action?.swimlineId][action?.columnTagId] - 1;
			return {
				...state,
				kanbanIssues: newItems,
				issueUpdated: state.issueUpdated + 1,
				swimlineCounts: counts,
			};
		}
		case actionTypes.ISSUE_DELETE: {
			if (action.swimlineId == undefined) return state;
			else {
				const issues = state.kanbanIssues;
				const swimlineCounts = state.swimlineCounts;

				issues[action.swimlineId] = issues[action.swimlineId]?.map(map => {
					const newIssues = map?.Issues?.filter(filter => filter?.Id != action?.id);
					let count = map?.Count;
					if (newIssues?.length < map?.Issues?.length) {
						swimlineCounts[action.swimlineId][map.ColumnTagId] =
							swimlineCounts[action.swimlineId][map.ColumnTagId] - 1;
						count = count - 1;
					}
					return {
						...map,
						Issues: newIssues,
						Count: count,
					};
				});
				return {
					...state,
					kanbanIssues: issues,
					swimlineCounts: swimlineCounts,
					issueUpdated: state.issueUpdated + 1,
					isCountUpdated: state.isCountUpdated + 1,
				};
			}
		}
		case actionTypes.KANBAN_ISSUE_UPDATE: {
			const newItems = state?.kanbanIssues;

			for (const [key] of Object.entries(newItems)) {
				newItems[parseInt(key)] = newItems[parseInt(key)]?.map(column => {
					let issueExists = false;
					if (
						action?.issue?.IssueToSwimlineToOrder?.some(
							(some: IIssueToSwimlineToOrder) =>
								(some?.KanbanSwimlineId ?? 0) == parseInt(key) &&
								some?.IssueKanbanTagId == column?.ColumnTagId
						)
					) {
						const issues = column?.Issues?.map(issue => {
							if (issue.Id == action.issue.Id) {
								issueExists = true;
								return {
									...action.issue,
									IssueToSwimlineToOrder: action?.issue?.IssueToSwimlineToOrder?.filter(
										(filter: IIssueToSwimlineToOrder) =>
											filter?.IssueKanbanTagId == column?.ColumnTagId &&
											(filter?.KanbanSwimlineId ?? 0) == parseInt(key)
									),
								};
							} else return issue;
						});

						return {
							...column,
							Issues: issueExists
								? issues
								: [
										{
											...action.issue,
											IssueToSwimlineToOrder: action?.issue?.IssueToSwimlineToOrder?.filter(
												(filter: IIssueToSwimlineToOrder) =>
													filter?.IssueKanbanTagId == column?.ColumnTagId &&
													filter?.KanbanSwimlineId == parseInt(key)
											),
										},
										...issues,
								  ],
						};
					} else
						return { ...column, Issues: column?.Issues?.filter(filter => filter?.Id != action?.issue?.Id) };
				});
			}

			return {
				...state,
				kanbanIssues: newItems,
				issueUpdated: state.issueUpdated + 1,
			};
		}
		case actionTypes.KANBAN_ISSUE_DID_CHANGE: {
			const newItems = state?.kanbanIssues;

			for (const [key] of Object.entries(newItems)) {
				newItems[parseInt(key)] = newItems[parseInt(key)]?.map(column => {
					let issueExists = false;
					if (
						action?.data?.issue?.IssueToSwimlineToOrder?.some(
							(some: IIssueToSwimlineToOrder) =>
								(some?.KanbanSwimlineId ?? 0) == parseInt(key) &&
								some?.IssueKanbanTagId == column?.ColumnTagId
						)
					) {
						const issues = column?.Issues?.map(issue => {
							if (issue?.Id == action?.data?.issue?.Id) {
								issueExists = true;
								return {
									...action?.data?.issue,
									IssueToSwimlineToOrder: action?.data?.issue?.IssueToSwimlineToOrder?.filter(
										(filter: IIssueToSwimlineToOrder) =>
											filter?.IssueKanbanTagId == column?.ColumnTagId &&
											(filter?.KanbanSwimlineId ?? 0) == parseInt(key)
									),
								};
							} else return issue;
						});

						return {
							...column,
							Issues: issues,
						};
					} else
						return {
							...column,
							Issues: column?.Issues?.filter(filter => filter?.Id != action?.data?.issue?.Id),
						};
				});
			}

			return {
				...state,
				kanbanIssues: newItems,
				issueUpdated: state.issueUpdated + 1,
			};
		}
		case actionTypes.GET_KANBAN_FORMS: {
			return { ...state, kanbanForms: action?.data };
		}
		case actionTypes.KANBAN_SET_FILTERS_NEW: {
			return {
				...state,
				filtersKanbanNew: action?.filter?.filterNew,
				kanbanIssues: {},
				swimlineCounts: {},
			};
		}
		case actionTypes.KANBAN_DELETE_FILTERS_NEW: {
			return {
				...state,
				filtersKanbanNew: action?.data,
				filters: {
					...state?.filters,
					filter: action?.data,
				},
				filterDidChange: state?.filterDidChange + 1,
				filterIsApplied: state?.filterIsApplied - 1,
				kanbanIssues: {},
				swimlineCounts: {},
			};
		}
		case actionTypes.KANBAN_TAGS_NOT_IN: {
			return {
				...state,
				filters: {
					...state?.filters,
					ExcludedTags: {
						...state?.filters?.Tags,
						choosenForms: action?.data?.choosenForms,
						choosenFieldDefinitions: action?.data?.choosenFieldDefinitions,
						choosenProjects: action?.data?.choosenProjects,
					},
				},
			};
		}
		case actionTypes.KANBAN_SET_FILTERS_NEW_TMP: {
			return {
				...state,
				filters: {
					...state?.filters,
					filter: action?.data,
				},
			};
		}
		case actionTypes.USE_CLOCK_PAUSE_FINISH:
		case actionTypes.KEY_WORKLOG_MANAGE:
		case actionTypes.USE_CLOCK: {
			const newCounts = state?.swimlineCounts;
			const setIssues = (worklogs: any[]) => {
				if (worklogs?.length > 0)
					for (const worklog of worklogs) {
						const newTagId = worklog?.Issue?.IssueField[0]?.FieldValue?.ValueTag?.MapTagToFieldDefinitionFieldDefinitionTag?.find(
							(find: IMapTagToFieldDefinition) =>
								state?.kanban?.KanbanColumnWrapper?.Tag?.some(
									some =>
										find?.TagId == some?.Id &&
										worklog?.Issue?.IssueField[0]?.FieldValue?.AttributeDefinitionId ==
											find?.FieldDefinitionId
								)
						)?.TagId;

						if (!newTagId) return;

						state?.kanbanSwimlines?.forEach(each => {
							let myIssue: IKanbanIssue | undefined = undefined;
							kanbanIssuesTmp[each?.Id] = kanbanIssuesTmp[each?.Id]?.map(column => {
								let isThisColumn = false;
								if (!myIssue) {
									const issueForMove = column?.Issues?.find(find => find.Id == worklog?.Issue?.Id);
									if (issueForMove?.Id) {
										isThisColumn = true;
										myIssue = issueForMove;
										newCounts[each?.Id][column?.ColumnTagId] =
											newCounts[each?.Id][column?.ColumnTagId] - 1;
									}
								}

								const columnIssues = column?.Issues?.filter(filter => filter?.Id != worklog?.Issue?.Id);

								return {
									...column,
									Issues: columnIssues,
									Count: isThisColumn ? column?.Count - 1 : column?.Count,
								};
							});
							if (myIssue)
								kanbanIssuesTmp[each?.Id] = kanbanIssuesTmp[each?.Id]?.map(map => {
									if (map?.ColumnTagId == newTagId) {
										newCounts[each.Id][newTagId] = (newCounts[each?.Id][newTagId] ?? 0) + 1;
										let counter = 0;
										return {
											...map,
											Issues: [
												{
													...myIssue,
													FieldValues:
														myIssue?.FieldValues?.map(fieldValue => {
															if (
																(
																	worklog?.Issue?.IssueField[0] ?? []
																)?.FieldValue?.ValueTag?.MapTagToFieldDefinitionFieldDefinitionTag?.some(
																	(some: IMapTagToFieldDefinition) =>
																		some?.FieldDefinitionId ==
																		fieldValue?.AttributeDefinitionId
																)
															)
																return {
																	...fieldValue,
																	ValueTag:
																		worklog?.Issue?.IssueField[0]?.FieldValue
																			?.ValueTag,
																	ValueTagId:
																		worklog?.Issue?.IssueField[0]?.FieldValue
																			?.ValueTagId,
																};
															else return fieldValue;
														}).filter((filter: IFieldValue) => {
															let value;
															if (
																!counter &&
																worklog?.Issue?.IssueField[0]?.FieldValue
																	?.AttributeDefinitionId ==
																	filter?.AttributeDefinitionId
															) {
																counter = 1;

																value = true;
															} else
																value =
																	worklog?.Issue?.IssueField[0]?.FieldValue
																		?.AttributeDefinitionId !=
																	filter?.AttributeDefinitionId;
															return value;
														}) ?? [],
												} as IKanbanIssue,
												...map?.Issues,
											],
											Count: map?.Count + 1,
										};
									} else return map;
								});
						});
					}
			};
			const activeWorkLogs = action?.worklogs?.active ?? [];
			const stoppedWorkLogs = action?.worklogs?.stopped ?? [];
			const kanbanIssuesTmp = state.kanbanIssues;
			setIssues(activeWorkLogs);
			setIssues(stoppedWorkLogs);

			return {
				...state,
				clockDidChange: state.clockDidChange + 1,
				issueUpdated: state.issueUpdated + 1,
				isCountUpdated: state.isCountUpdated + 1,
				kanbanIssues: kanbanIssuesTmp,
				swimlineCounts: newCounts,
			};
		}
		case actionTypes.WORKLOG_FETCH_ACTIVE_NEW: {
			return {
				...state,
				clockDidChange: state.clockDidChange + 1,
			};
		}
		case actionTypes.FETCH_PROJECTS_WITH_FORMS: {
			return {
				...state,
				projectsWithForms: action.projectsWithForms,
			};
		}
		case actionTypes.FETCH_FORM_MAPPINGS: {
			return {
				...state,
				kanbanFormMappings: action.mappings ?? [],
				kanbanEditMappings: action.mappings ?? [],
			};
		}
		case actionTypes.ISSUE_MODAL_GET_FORMS_IN_KANBAN: {
			return {
				...state,
				kanbanFormMappings: action?.data?.mappings ?? [],
			};
		}
		case actionTypes.RESET_CLOCK_LISTENER: {
			return {
				...state,
				clockDidChange: 0,
			};
		}
		case actionTypes.SET_KANBAN_EDIT_MAPPINGS: {
			return {
				...state,
				kanbanEditMappings: action?.data,
			};
		}

		case actionTypes.CLEAR_ISSUES: {
			return {
				...state,
				kanbanIssues: [],
				filterDidChange: 0,
			};
		}
		case actionTypes.FETCH_SWIMLINES_FOR_KANBAN: {
			return {
				...state,
				kanbanSwimlines: action?.data,
				kanbanIssues: {},
			};
		}

		case actionTypes.DELETE_SWIMLINE: {
			return {
				...state,
				kanbanIssues: {},
				kanban: {
					...state.kanban,
					KanbanSwimline: state?.kanbanSwimlines?.filter(swimline => swimline?.Id != action?.swimlineId),
				},
				kanbanSwimlines: state?.kanbanSwimlines?.filter(swimline => swimline?.Id != action?.swimlineId),
			};
		}

		case actionTypes.FILTER_KANBAN_PROJECT_DELETE: {
			const projects = state?.filters?.projects?.filter(x => x?.Id != action.data);
			const tagsChoosenProjects = state?.filters?.Tags?.choosenProjects?.filter(x => x?.Id != action.data);
			const statusChoosenProjects = state?.filters?.Status?.choosenProjects?.filter(x => x?.Id != action.data);
			return {
				...state,
				filters: {
					...state?.filters,
					projects: projects ?? [],
					Tags: {
						...state?.filters?.Tags,
						choosenProjects: tagsChoosenProjects,
					},
					Status: {
						...state?.filters?.Status,
						choosenProjects: statusChoosenProjects,
					},
				},
			};
		}
		case actionTypes.PORTAL_FILTER_STATUS_OPTIONS:
		case actionTypes.FILTER_STATUS_OPTIONS: {
			return {
				...state,
				filters: {
					...state?.filters,
					Status: {
						...state?.filters?.Status,
						personalizedForms: action?.data?.IssueForms,
						personalizedFieldDefinitions: action?.data?.FieldDefinitions,
						personalizedTags: action?.data?.Tags,
						personalizedProjects: action?.data?.Projects,
					},
				},
			};
		}
		case actionTypes.PORTAL_SET_FILTERS: {
			return {
				...state,
				filters: {
					...state?.filters,
					filter: action?.filter,
				},
			};
		}
		case actionTypes.KANBAN_SET_STATUS: {
			return {
				...state,
				filters: {
					...state?.filters,
					Status: {
						...state?.filters?.Status,
						choosenForms: action?.data?.choosenForms,
						choosenFieldDefinitions: action?.data?.choosenFieldDefinitions,
						choosenProjects: action?.data?.choosenProjects,
					},
				},
			};
		}
		case actionTypes.PORTAL_FILTER_TAGS_OPTIONS:
		case actionTypes.FILTER_TAGS_OPTIONS: {
			return {
				...state,
				filters: {
					...state?.filters,
					Tags: {
						...state?.filters?.Tags,
						personalizedForms: action?.data?.IssueForms,
						personalizedFieldDefinitions: action?.data?.FieldDefinitions,
						personalizedTags: action?.data?.Tags,
						personalizedProjects: action?.data?.Projects,
					},
					ExcludedTags: {
						...state?.filters?.Tags,
						personalizedForms: action?.data?.IssueForms,
						personalizedFieldDefinitions: action?.data?.FieldDefinitions,
						personalizedTags: action?.data?.Tags,
						personalizedProjects: action?.data?.Projects,
					},
				},
			};
		}
		case actionTypes.PORTAL_SET_TAGS: {
			return {
				...state,
				filters: {
					...state?.filters,
					Tags: {
						...state?.filters?.Tags,
						choosenForms: action?.data?.choosenForms,
						choosenProjects: action?.data?.choosenProjects,
						choosenFieldDefinitions: action?.data?.choosenFieldDefinitions,
					},
				},
			};
		}
		case actionTypes.PORTAL_TAGS_NOT_IN: {
			return {
				...state,
				filters: {
					...state?.filters,
					ExcludedTags: {
						...state?.filters?.Tags,
						choosenForms: action?.data?.choosenForms,
						choosenProjects: action?.data?.choosenProjects,
						choosenFieldDefinitions: action?.data?.choosenFieldDefinitions,
					},
				},
			};
		}
		case actionTypes.ISSUE_SET_FILTERS_NEW_V2: {
			return {
				...state,
				filters: {
					...state?.filters,
					filter: action?.data,
				},
			};
		}
		case actionTypes.PORTAL_SET_EXCLUDED_TAGS: {
			return {
				...state,
				filters: {
					...state?.filters,
					filter: action?.filter,
				},
			};
		}
		case actionTypes.FETCH_ALL_USERS: {
			return {
				...state,
				filters: {
					...state?.filters,
					carouselUsers: action.taskerUsers,
				},
			};
		}
		case actionTypes.CREATE_QUICK_FILTERS: {
			return {
				...state,
				kanban: {
					...state?.kanban,
					Quickfilter: [...(state?.kanban?.Quickfilter ?? []), action?.data],
				},
			};
		}
		case actionTypes.FILTER_CUSTOMER_FILTERS_SIMPLIFIED: {
			return {
				...state,
				filters: {
					...state?.filters,
					customerFiltersSimplified: action?.data,
					Options: {
						...state?.filters?.Options,
						...getDataFromCustomerFiltersSimplify(action?.data),
					},
				},
			};
		}
		case actionTypes.FILTER_CUSTOMER_FILTERS: {
			return {
				...state,
				filters: {
					...state?.filters,
					customerFilters: action?.data,
					Options: {
						...state?.filters?.Options,
						...getDataCustomerFilters(action?.data),
					},
				},
			};
		}
		case actionTypes.KANBAN_SET_QUICK_FILTERS:
		case actionTypes.FILTER_APPLY_KANBAN: {
			// const cookies = new Cookies();
			if (action?.filter) localStorage.setItem('kanbanFilter', JSON.stringify(action?.filter as FullFilters));
			// if (action?.filter)
			// 	cookies.set('kanbanFilter', JSON.stringify(action?.filter as FullFilters), { path: '/' });

			return {
				...state,
				filter: action?.filter,
				filterIsApplied: state?.filterIsApplied + 1,
				filterDidChange: state?.filterDidChange + 1,
				filters: {
					...state?.filters,
					filter: action?.filter,
				},
			};
		}

		case actionTypes.SET_NEW_USER_CONFIGURATION: {
			return {
				...state,
				kanban: {
					...state?.kanban,
					SwimlineConfigurationUser: action.user,
				},
			};
		}

		case actionTypes.KANBAN_LITE_MODE_CHANGE: {
			return {
				...state,
				isLiteMode: action?.configuration?.ValueBoolean,
			};
		}
		case actionTypes.UPDATE_QUICK_FILTERS: {
			return {
				...state,
				kanban: {
					...state?.kanban,
					Quickfilter: state?.kanban?.Quickfilter?.map(x => {
						if (x?.Id === action?.id) return { ...x, Name: action?.name };
						else return x;
					}),
				},
			};
		}
		case actionTypes.DELETE_QUICK_FILTERS: {
			return {
				...state,
				kanban: {
					...state?.kanban,
					Quickfilter: state?.kanban?.Quickfilter?.filter(x => x?.Id != action?.id),
				},
			};
		}
		case actionTypes.TASKERUSERSETTIGNS_UPDATE_PREFERENCES: {
			if (action?.data?.ParameterId === EParameter.User_UISimplifyFilters)
				return {
					...state,
					filters: {
						...state?.filters,
						customerFiltersSimplified: [],
						customerFilters: [],
					},
				};

			return state;
		}
		default: {
			return state;
		}
	}
};

export default kanbanReducer;

export interface IKanbanFilters {
	projects?: IProject[];
	carouselUsers?: Partial<ITaskerUser>[];
	filter: FullFilters;
	Status: {
		personalizedForms?: IPortalFilterForms[];
		personalizedFieldDefinitions?: IPortalFilterFieldDefinition[];
		personalizedTags?: IPortalFilterTags[];
		personalizedProjects?: IProject[];
		choosenForms: IPortalFilterForms[];
		choosenFieldDefinitions: IPortalFilterFieldDefinition[];
		choosenProjects: IProject[];
	};
	Tags: {
		personalizedForms?: IPortalFilterForms[];
		personalizedFieldDefinitions?: IPortalFilterFieldDefinition[];
		personalizedTags?: IPortalFilterTags[];
		personalizedProjects?: IProject[];
		choosenForms: IPortalFilterForms[];
		choosenFieldDefinitions: IPortalFilterFieldDefinition[];
		choosenProjects: IProject[];
	};
	ExcludedTags: {
		personalizedForms?: IPortalFilterForms[];
		personalizedFieldDefinitions?: IPortalFilterFieldDefinition[];
		personalizedTags?: IPortalFilterTags[];
		personalizedProjects?: IProject[];
		choosenForms: IPortalFilterForms[];
		choosenFieldDefinitions: IPortalFilterFieldDefinition[];
		choosenProjects: IProject[];
	};
}
