import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources.js';

export const Resources = {
	issuePath: '?issueId=',
	projectsPath: '/projects/',
	...GlobalResources,
};

export const Translation = {
	pl: {
		CustomerDetail: {
			Content: {
				Invoices: {
					HeaderInvoice: {
						InvoiceModal: {
							InvoiceModalContent: {
								Items: {
									hooks: {
										invoiceItemName: 'Nazwa przedmiotu',
										invoiceItemCreatedBy: 'Utworzony przez',
										invoiceItemMeasureKind: 'Jednostka miary',
										invoiceItemNetPrice: 'Wartość netto',
										invoiceItemQuantity: 'Ilość',
										invoiceItemVat: 'VAT',
										invoiceItemGrossPrice: 'Wartość brutto',
										invoiceItemGrossPriceSum: 'Suma wartości brutto',
										invoiceItemNetPriceSum: 'Suma wartości netto',
									},
								},
							},
						},
					},
				},
			},
		},
	},
	en: {
		CustomerDetail: {
			Content: {
				Invoices: {
					HeaderInvoice: {
						InvoiceModal: {
							InvoiceModalContent: {
								Items: {
									hooks: {
										invoiceItemName: 'Item name',
										invoiceItemCreatedBy: 'Created by',
										invoiceItemMeasureKind: 'Measure kind',
										invoiceItemNetPrice: 'Net price',
										invoiceItemQuantity: 'Quantity',
										invoiceItemVat: 'VAT',
										invoiceItemGrossPrice: 'Gross price',
										invoiceItemGrossPriceSum: 'Sum gross price',
										invoiceItemNetPriceSum: 'Sum net price',
									},
								},
							},
						},
					},
				},
			},
		},
	},
};
