import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'uuid';

import { EDropzoneFileStatus, FileTypesExtended, IAttachment } from '@skillandchill/tasker-types';
import { successVariant } from '@skillandchill/tasker-widgets2/dist/utils';

import { addLocalAttachment, attachment_delete, attachment_issue_post } from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';
import { useTrans } from '@/utils/hooks/useTrans';

import { Dropzone } from 'view/DelegationsList/DelegationModal/DelegationModalContent/DelegationDetails/DelegationSettlementAttachments/Dropzone';
import {
	EDropzonePlacement,
	EDropzoneType,
} from 'view/DelegationsList/DelegationModal/DelegationModalContent/DelegationDetails/DelegationSettlementAttachments/Dropzone/model';
import { issueModalDataContext } from 'view/IssueModelNew';
import { EIssueModalDetailsPageFields } from 'view/IssueModelNew/IssueModalContent/IssueDetailsPage/model';
import { IAttachmentDrop } from 'view/IssueModelNew/IssueModalContent/IssueDetailsPage/TabsContent/Attachment/model';

import { Resources } from './resources';
import { useStyles } from './styles';

export const Attachment = () => {
	const dispatch: Dispatch = useDispatch();
	const classes = useStyles();
	const snackBar = useSnackbar();
	const { handleInputChange, issueState } = useContext(issueModalDataContext);
	const { t } = useTrans('IssueModalNew.IssueModalContent.IssueDetailsPage.TabsContent.Attachment');

	const issue = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueModal);
	const token = useSelector((state: IReducerState) => state?.Session?.access_token);
	const attachmentsSelector: IAttachment[] = useSelector(
		(state: IReducerState) => state?.IssueModalEditor?.attachmentIssue
	);

	const [attachments, setAttachments] = useState<IAttachmentDrop[]>([]);

	useEffect(() => {
		const condition = attachmentsSelector && attachmentsSelector?.length > 0 && (issueState?.Id ?? 0) > 0;
		if (condition) {
			setAttachments(
				attachmentsSelector?.map(x => {
					const att = (x as unknown) as IAttachmentDrop;
					return {
						...att,
						fileLocalId: att?.fileLocalId ?? uuid(),
						fileStatus: EDropzoneFileStatus.Saved,
					};
				})
			);
		}
	}, [attachmentsSelector, issueState?.Id]);

	const handleGetFilename = (attachment: IAttachmentDrop) => {
		if (attachment?.FileName) return attachment?.FileName;
		if (attachment?.file) return attachment?.file?.name;
		return Resources.GlobalResources.error;
	};

	const handleGetAttachmentId = (attachment: IAttachmentDrop) => {
		if (attachment?.Id) return attachment?.Id;
		return -1;
	};

	const handleAttachmentRemove = (attachment: IAttachmentDrop) => {
		if (issue?.Id && attachment?.Id) {
			dispatch(attachment_delete(attachment?.Id)).finally(() => {
				snackBar.enqueueSnackbar(t('deleteSuccess'), successVariant);
			});
		} else {
			setAttachments(prev => prev.filter(item => item.fileLocalId !== attachment.fileLocalId));
		}
	};

	const handleAttachmentsDrop = async (attachments: IAttachmentDrop[]) => {
		const promises = [];
		if (issue?.Id) {
			for (let i = 0; i < attachments.length; i++) {
				const attachment = attachments[i];
				if (attachment?.file) {
					promises.push(dispatch(attachment_issue_post(attachment, attachment?.file, issue?.Id)));
				}
				if (promises?.length > 0) {
					await Promise.all(promises);
					snackBar.enqueueSnackbar(t('uploadSuccess'), successVariant);
				}
			}
		} else {
			const data: IAttachmentDrop[] = attachments?.map(x => ({ ...x, File: x?.file }));
			promises.push(dispatch(addLocalAttachment(attachments)));
			handleInputChange(EIssueModalDetailsPageFields.attachment, data);
			// setAttachments(data);
			await Promise.all(promises);
		}
	};

	return (
		<div className={classes.root}>
			<Dropzone<IAttachmentDrop>
				baseProps={{
					attachments: attachments,
					setAttachments: setAttachments,
					isEdit: true,
					allowedFiletypes: FileTypesExtended.Default,
					maxFilesize: 104857600,
					bearerToken: token,
				}}
				handlers={{
					type: EDropzoneType.Drop,
					getFilename: attachment => handleGetFilename(attachment),
					getAttachmentId: attachment => handleGetAttachmentId(attachment),
					onRemove: attachment => handleAttachmentRemove(attachment),
					onDrop: attachments => handleAttachmentsDrop(attachments),
				}}
				classes={classes}
				dropZonePlacement={EDropzonePlacement.Top}
			/>
		</div>
	);
};
