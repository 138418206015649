import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import useDownloader from 'react-use-downloader';

import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';

import { CollapseControl, VisibilityControl } from '@skillandchill/tasker-widgets2';
import { errorVariant } from '@skillandchill/tasker-widgets2/dist/utils';

import { useTrans } from '@/utils/hooks/useTrans';

import { EDropzoneType, IDropzoneAttachment } from '../model';

import { Props, EDropzoneFileStatus } from './model';
import { PopupDialog } from './PopupDialog';
import { Resources } from './resources';
import { useStyles } from './styles';

export const DropzoneFile = <T extends IDropzoneAttachment>(props: Props<T>): JSX.Element => {
	const { baseProps, handlers, classes: classesInput } = props;
	const { index, attachment, setAttachments, isEdit, isDisabled, setIsHovered, bearerToken } = baseProps;
	const { type, getFilename, getAttachmentId, onRemove } = handlers;

	const { download, percentage, isInProgress, error } = useDownloader({
		headers: { Authorization: `Bearer ${bearerToken}` },
	});
	const classes = useStyles();
	const snackBar = useSnackbar();
	const { t } = useTrans(
		'DelegationList.DelegationModal.DelegationModalContent.DelegationDetails.DelegationAttachments.Dropzone.DropzoneAttachmentRecord'
	);

	const [uploadProgress, setUploadProgress] = useState<number>(0);
	const [fileStatus, setFileStatus] = useState<EDropzoneFileStatus>(
		attachment?.fileStatus ?? EDropzoneFileStatus.Waiting
	);
	const [openPreview, setOpenPreview] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	useEffect(() => {
		setAttachments(prev => {
			return prev.map(prevAttachment =>
				prevAttachment?.fileLocalId === attachment?.fileLocalId
					? { ...prevAttachment, fileStatus }
					: prevAttachment
			);
		});
	}, [fileStatus]);

	const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const handleCollapse = (fileLocalId: string) => {
		setAttachments(prev =>
			prev.map(attachment =>
				attachment?.fileLocalId === fileLocalId
					? { ...attachment, collapsed: !attachment.collapsed }
					: attachment
			)
		);
	};

	const handleRemove = (attachment: T) => {
		const consent = confirm(`${t('removeFileMsg')}${getFilename(attachment)}${Resources.questionMark}`);
		if (consent) {
			setAttachments(prev =>
				prev.filter(prevAttachment => prevAttachment?.fileLocalId !== attachment.fileLocalId)
			);
			isEdit && onRemove(attachment);
		}
	};

	const handleFormReady = (fileLocalId: string) => {
		setAttachments(prev =>
			prev.map(attachment =>
				attachment?.fileLocalId === fileLocalId ? { ...attachment, formReady: true } : attachment
			)
		);
	};

	const handleFormUnready = (fileLocalId: string) => {
		setAttachments(prev =>
			prev.map(attachment =>
				attachment?.fileLocalId === fileLocalId ? { ...attachment, formReady: false } : attachment
			)
		);
	};

	const handleCollapsed = (fileLocalId: string) => {
		setAttachments(prev =>
			prev.map(attachment =>
				attachment?.fileLocalId === fileLocalId ? { ...attachment, collapsed: true } : attachment
			)
		);
	};

	const showFileStatus = (attachment: T) => {
		if (isEdit) {
			switch (fileStatus) {
				case EDropzoneFileStatus.Waiting:
					if (attachment?.file) {
						setFileStatus(EDropzoneFileStatus.Pending);
						return t('fileFormPending');
					}
					return '';
				case EDropzoneFileStatus.Pending:
					if (attachment.formReady) {
						setFileStatus(EDropzoneFileStatus.Uploading);
						return `${uploadProgress}${Resources.percent}`;
					}
					return t('fileFormPending');
				case EDropzoneFileStatus.Uploading:
					if (uploadProgress == 100) {
						setFileStatus(EDropzoneFileStatus.Uploaded);
						setUploadProgress(0);
						return t('fileUploaded');
					}
					return `${uploadProgress}${Resources.percent}`;
				case EDropzoneFileStatus.Uploaded:
					return t('fileUploaded');
				case EDropzoneFileStatus.Downloading:
					if (error) {
						setFileStatus(EDropzoneFileStatus.Failed);
						snackBar.enqueueSnackbar(t('errorDownloadFile'), errorVariant);
						return '';
					}
					if (percentage == 100 || !isInProgress) {
						setFileStatus(EDropzoneFileStatus.Downloaded);
						return t('fileDownloaded');
					}

					return `${percentage}${Resources.percent}`;
				case EDropzoneFileStatus.Downloaded:
					return t('fileDownloaded');
			}
		} else {
			switch (fileStatus) {
				case EDropzoneFileStatus.Waiting:
					if (attachment?.file) {
						setFileStatus(EDropzoneFileStatus.Pending);
						return t('fileFormPending');
					}
					return '';
				case EDropzoneFileStatus.Pending:
					if (attachment.formReady) {
						setFileStatus(EDropzoneFileStatus.Saved);
						return t('fileSaved');
					}
					return t('fileFormPending');
				case EDropzoneFileStatus.Saved:
					return t('fileSaved');
			}
		}
	};
	const memoizedShowFileStatus = useMemo(() => showFileStatus(attachment), [
		isEdit,
		fileStatus,
		attachment,
		uploadProgress,
		percentage,
		error,
	]);

	const checkShowBar = () => {
		const validateStatus = [EDropzoneFileStatus.Downloading, EDropzoneFileStatus.Uploading];
		return validateStatus.includes(fileStatus);
	};

	const checkIsImage = () => {
		const fileName = getFilename(attachment);
		const extenstion = fileName.substring(fileName.indexOf(Resources.dot) + 1, fileName.length);
		return Resources.imageExtensions.includes(extenstion);
	};

	const downloadFile = () => {
		setFileStatus(EDropzoneFileStatus.Downloading);
		download(Resources.urlDownload(getAttachmentId(attachment)), getFilename(attachment));
		handleCloseMenu();
	};

	return (
		<div
			className={classesInput?.tile ? clsx(classes.dropzoneFile, classesInput.tile) : classes.dropzoneFile}
			onMouseEnter={() => setIsHovered(false)}
			onMouseLeave={() => setIsHovered(true)}
			onClick={event => event.preventDefault()}
		>
			<div className={classes.dropzoneFileContent}>
				<div className={classes.flexYCenter}>
					<IconButton
						onClick={() => {
							isEdit && setOpenPreview(true);
						}}
						disabled={!isEdit}
						className={
							classesInput?.iconButton
								? clsx(classes.dropzoneFileIconButton, classesInput.iconButton)
								: classes.dropzoneFileIconButton
						}
					>
						<DescriptionIcon
							className={
								classesInput?.icon
									? clsx(classes.dropzoneFileIcon, classesInput.icon)
									: classes.dropzoneFileIcon
							}
						/>
					</IconButton>
					<div className={classes.dropzoneFileInfo}>
						<Typography variant={Resources.GlobalResources.body1}>{getFilename(attachment)}</Typography>
						<div className={classes.flexYCenter}>
							{isEdit && checkShowBar() && (
								<div className={classes.dropzoneFileLoadingBar}>
									<div
										style={{
											width: `${
												fileStatus === EDropzoneFileStatus.Downloading
													? percentage
													: uploadProgress
											}${Resources.percent}`,
											transition: Resources.loadingBarProgressTransition,
										}}
										className={classes.dropzoneFileLoadingBarProgress}
									/>
								</div>
							)}
							<Typography variant={Resources.GlobalResources.body2}>{memoizedShowFileStatus}</Typography>
						</div>
					</div>
				</div>
				<div className={classes.flexYCenter}>
					{type === EDropzoneType.AttachmentForm && (
						<IconButton
							size={Resources.GlobalResources.small}
							onClick={() => handleCollapse(attachment?.fileLocalId as string)}
						>
							<ExpandMoreIcon />
						</IconButton>
					)}
					<IconButton size={Resources.GlobalResources.small} onClick={handleOpenMenu}>
						<MoreVertIcon />
					</IconButton>
					<Menu
						open={open}
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: Resources.GlobalResources.bottom,
							horizontal: Resources.GlobalResources.right,
						}}
						transformOrigin={{
							vertical: Resources.GlobalResources.top,
							horizontal: Resources.GlobalResources.right,
						}}
						onClose={handleCloseMenu}
						className={classes.dropzoneFileMenu}
					>
						<VisibilityControl condition={isEdit && getAttachmentId(attachment) != -1}>
							<MenuItem
								dense
								onClick={() => {
									setOpenPreview(true);
									handleCloseMenu();
								}}
								className={classes.dropzoneFileDownload}
							>
								<ListItemIcon>
									<SearchIcon />
								</ListItemIcon>
								<ListItemText>{t('preview')}</ListItemText>
							</MenuItem>
						</VisibilityControl>
						<VisibilityControl condition={isEdit && getAttachmentId(attachment) != -1}>
							<MenuItem dense onClick={downloadFile} className={classes.dropzoneFileDownload}>
								<ListItemIcon>
									<FileDownloadIcon />
								</ListItemIcon>
								<ListItemText>{t('menuDownload')}</ListItemText>
							</MenuItem>
						</VisibilityControl>
						<VisibilityControl condition={type === EDropzoneType.AttachmentForm && !isDisabled}>
							<MenuItem
								dense
								onClick={() => {
									handleFormUnready(attachment?.fileLocalId as string);
									handleCollapsed(attachment?.fileLocalId as string);
									handleCloseMenu();
								}}
								className={classes.dropzoneFileEdit}
							>
								<ListItemIcon>
									<EditIcon />
								</ListItemIcon>
								<ListItemText>{t('menuEdit')}</ListItemText>
							</MenuItem>
						</VisibilityControl>
						<VisibilityControl condition={!isDisabled}>
							<Divider />
							<MenuItem
								dense
								onClick={() => {
									handleRemove(attachment);
									handleCloseMenu();
								}}
								className={classes.dropzoneFileDelete}
							>
								<ListItemIcon>
									<DeleteIcon />
								</ListItemIcon>
								<ListItemText>{t('menuRemove')}</ListItemText>
							</MenuItem>
						</VisibilityControl>
					</Menu>
				</div>
			</div>
			{type === EDropzoneType.AttachmentForm && (
				<CollapseControl condition={attachment.collapsed} className={classes.fullWidth}>
					<div className={classes.dropzoneFileCollapse}>
						{!attachment.formReady
							? handlers.renderForm(attachment, index)
							: handlers.renderFormInfo(attachment, index)}
						{!attachment.formReady && (
							<div className={classes.dropzoneFileCollapseFooter}>
								<Button
									variant={Resources.GlobalResources.contained}
									onClick={() => {
										const error = handlers.onFormValidate(attachment);
										if (!error) {
											handleFormReady(attachment?.fileLocalId as string);
											handlers.onFormSubmit(attachment, index, setUploadProgress);
										} else {
											snackBar.enqueueSnackbar(t('formValidateError'), errorVariant);
										}
									}}
									className={classes.footerButton}
								>
									<SaveIcon />
									{t('save')}
								</Button>
							</div>
						)}
					</div>
				</CollapseControl>
			)}
			<PopupDialog
				open={openPreview}
				onClose={() => setOpenPreview(false)}
				iFrameSrc={Resources.urlPreview(getAttachmentId(attachment), bearerToken)}
				isImage={checkIsImage()}
				title={getFilename(attachment)}
			/>
		</div>
	);
};
