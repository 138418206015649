import { EFieldDefinitionType, IIssue } from '@skillandchill/tasker-types';

import { IIssueModalData } from '../IssueModalContent/IssueDetailsPage/model';

export const mapInitialStateToIssueState = (issue: Partial<IIssue>): IIssueModalData => {
	return {
		Id: issue?.Id,
		Assigned: issue?.Members?.Assigned ?? [],
		Observer: issue?.Members?.Observer ?? [],
		Auditor: issue?.Members?.Auditor ?? [],
		Reporter: issue?.Members?.Reporter ?? [],
		DescriptionHtml: issue?.DescriptionHtml ?? '',
		Description: issue?.Description ?? '',
		ProjectId: issue?.ProjectId ?? null,
		Title: issue?.Title ?? '',
		ProjectIssueTypeId: issue?.ProjectIssueTypeId ?? null,
		Properties: {
			[EFieldDefinitionType.Boolean]: {},
			[EFieldDefinitionType.Date]: {},
			[EFieldDefinitionType.Decimal]: {},
			[EFieldDefinitionType.Integer]: {},
			[EFieldDefinitionType.Text]: {},
			[EFieldDefinitionType.TagGroup]: {},
			[EFieldDefinitionType.DateTime]: {},
			[EFieldDefinitionType.Dictionary]: {},
			[EFieldDefinitionType.TextArea]: {},
		},
	};
};
