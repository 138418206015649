import clsx from 'clsx';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Typography } from '@mui/material';

import {
	EFieldDefinitionType,
	IDictionaryItem,
	IErrorValidationV2,
	IFieldValue,
	ITag,
} from '@skillandchill/tasker-types';
import { IIssueFormSection } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/issueFormSection';
import { IIssueFormSectionToIssueField } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/issueFormSectionToIssueField';
import { InputLabelNew, VisibilityControl } from '@skillandchill/tasker-widgets2';

import { EValueToUse, IssueFormSectionsWidget } from '@/layouts/Widgets/IssueFormSectionWidget';
import { IReducerState } from '@/store/reducers/types';
import { useTrans } from '@/utils/hooks/useTrans';
import { issueModalDataContext } from '@/views/IssueModelNew';
import { IExtendedFieldValue } from '@/views/IssueModelNew/modal';

import { EIssueModalDetailsPageFields } from '../../model';

import { useStyles } from './styles';
import { getChangesInItems } from './utils/getItemsToDelete';

export const DependentInputs = () => {
	const classes = useStyles();

	const { t } = useTrans('IssueModelNew.IssueMainData');

	const formState = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueForm);

	const {
		issueState,
		inputsInEdit,
		handleEditStateChange,
		issueErrors,
		isEdittingIssue,
		handleReset,
		setIssueState,
		setFieldValues,
	} = useContext(issueModalDataContext);

	const propertiesError = (issueErrors.find(error => error.inputName === EIssueModalDetailsPageFields.properties) ??
		{}) as IErrorValidationV2<EIssueModalDetailsPageFields> | undefined;

	const anyRequiredFieldMissing = Boolean(propertiesError?.additionalData ?? false);

	const handleChange = (
		val: boolean | ITag | ITag[] | string | number | Date | IDictionaryItem | IDictionaryItem[],
		issueFormSectionToIssueField: IIssueFormSectionToIssueField
	) => {
		const fieldDefinitionTypeId = issueFormSectionToIssueField?.AttributeDefinition
			?.FieldDefinitionTypeId as number;

		setIssueState(prevState => ({
			...prevState,
			Properties: {
				...(prevState?.Properties ?? {}),
				[`${fieldDefinitionTypeId}`]: {
					...((prevState?.Properties as any)[String(fieldDefinitionTypeId)] ?? {}),
					[String(issueFormSectionToIssueField?.AttributeDefinitionId)]: val,
				},
			},
		}));

		const initialFieldValues = issueFormSectionToIssueField?.AttributeDefinition?.FieldValue;

		function removeItemsWithSectionAttributeDefinitionId(fieldValue: IFieldValue | Partial<IExtendedFieldValue>) {
			return fieldValue?.AttributeDefinitionId !== issueFormSectionToIssueField?.AttributeDefinitionId;
		}

		const { addedFieldValues, deletedFieldValues } = getChangesInItems(issueFormSectionToIssueField, val);

		setFieldValues(prevState => {
			let fieldValuesToDelete: Partial<IExtendedFieldValue>[] = [
				...(prevState?.fieldsValueToDelete ?? []),
			]?.filter(removeItemsWithSectionAttributeDefinitionId);

			let fieldValuesToCreate: Partial<IExtendedFieldValue>[] = [
				...(prevState?.fieldsValueToCreate ?? []),
			]?.filter(removeItemsWithSectionAttributeDefinitionId);

			if (initialFieldValues?.length) {
				fieldValuesToDelete = [...fieldValuesToDelete, ...deletedFieldValues] as IExtendedFieldValue[];
			}

			fieldValuesToCreate = [...fieldValuesToCreate, ...addedFieldValues] as IExtendedFieldValue[];

			return {
				...prevState,
				fieldsValueToDelete: fieldValuesToDelete,
				fieldsValueToCreate: fieldValuesToCreate,
			};
		});
	};

	return (
		<div className={classes.container}>
			<InputLabelNew
				inEdit={isEdittingIssue ? !!inputsInEdit[EIssueModalDetailsPageFields.properties] : true}
				canSetInputInNonEdit={isEdittingIssue}
				className={classes.issuePropertiesContainer}
				label={t('issueProperties')}
				handleInEditStateChange={() => handleEditStateChange(EIssueModalDetailsPageFields.properties, true)}
				handleReset={() => handleReset(EIssueModalDetailsPageFields.properties)}
			/>
			<VisibilityControl
				condition={!issueState?.ProjectIssueTypeId}
				alternative={
					<div className={classes.issuePropertiesInnerContainer}>
						<IssueFormSectionsWidget
							IssueFormSections={formState?.IssueFormSection as IIssueFormSection[]}
							handleChange={handleChange}
							state={issueState?.Properties as Record<EFieldDefinitionType, Record<number, unknown>>}
							valueToUse={EValueToUse.FieldValue}
							inEdit={isEdittingIssue ? !!inputsInEdit[EIssueModalDetailsPageFields.properties] : true}
						/>
						<VisibilityControl condition={anyRequiredFieldMissing}>
							<Typography className={classes.errorText}>Brak wartości w wymaganych polach</Typography>
						</VisibilityControl>
					</div>
				}
			>
				<div className={clsx(classes.flexColumn, classes.noIssueType)}>
					<QuestionMarkIcon className={classes.noIssueTypeIcon} />
					<Typography className={classes.noIssueTypeText}>Brak typu zgłoszenia</Typography>
				</div>
			</VisibilityControl>
		</div>
	);
};
