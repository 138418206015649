import clsx from 'clsx';
import React from 'react';
import { components } from 'react-select';

import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';

import { VisibilityControl } from '@skillandchill/tasker-widgets2';

import { useStyles } from './styles';

// import { EIssueRoleString } from '../model';

export const ValueContainer = ({ children, hasValue, ...rest }: any) => {
	const { isMoreThanTwoSelected, valuesLimit = 3 } = rest.selectProps; // HERE
	const classes = useStyles();

	if (!hasValue) {
		return <components.ValueContainer {...rest}>{children}</components.ValueContainer>;
	}

	const [chips, otherChildren] = children;

	const firstThreeElements = Array.from(children?.[0])?.slice(0, valuesLimit);

	return (
		<components.ValueContainer {...rest}>
			{firstThreeElements}

			{otherChildren}
			<VisibilityControl condition={children?.[0]?.length > valuesLimit}>
				<div className={clsx(classes.flexYCenter, classes.counter)}>
					<AddIcon className={classes.icon} />
					<Typography className={classes.counterText}>{children?.[0]?.length - valuesLimit}</Typography>
				</div>
			</VisibilityControl>
		</components.ValueContainer>
	);
};
