import { Translation as ColumnsKanbanContent } from './KanbanContent/ColumnsKanbanContent/resources';
import {
	Translation as ColumnsKanbanContentSwimlineConfiguration,
	Translation as SwimlineConfiguration,
} from './KanbanContent/ColumnsKanbanContent/SwimlineConfiguration/resources';
import { Translation as SwimlineConfigurationDrawer } from './KanbanContent/ColumnsKanbanContent/SwimlineConfiguration/SwimlineConfigurationDrawer/resources';
import { Translation as SwimlineCreationModal } from './KanbanContent/ColumnsKanbanContent/SwimlineConfiguration/SwimlineConfigurationDrawer/SwimlineCreationModal/resources';
import { Translation as SwimlineTile } from './KanbanContent/ColumnsKanbanContent/SwimlineConfiguration/SwimlineConfigurationDrawer/SwimlineTile/resources';
import { Translation as TaskListHeader } from './KanbanContent/ColumnsKanbanContent/TaskListHeader/resources';
import { Translation as Tabs } from './KanbanContent/EditKanbanContent/resources';
import { Translation as AddColumn } from './KanbanContent/EditKanbanContent/TabsContent/GeneralTab/AddColumn/resources';
import { Translation as InEditHeader } from './KanbanContent/EditKanbanContent/TabsContent/GeneralTab/InnerEditList/TaskListEdit/TaskListHeader/InEditHeader/resources';
import { Translation as GeneralTab } from './KanbanContent/EditKanbanContent/TabsContent/GeneralTab/resources';
import { Translation as MultiTag } from './KanbanContent/EditKanbanContent/TabsContent/MappingsTab/InnerEditList/KanbanFormSection/KanbanIssueForm/IssueFormSectionWithCollapse/FieldDefinition/MultiTag/resources';
import { Translation as LeftSideButtons } from './KanbanContent/EditKanbanContent/TabsContent/MappingsTab/LeftSideButtons/resources';
import { Translation as MappingsTab } from './KanbanContent/EditKanbanContent/TabsContent/MappingsTab/resources';
import { Translation as Actions } from './KanbanCreator/Actions/resources';
import { Translation as CreationPopupContent } from './KanbanCreator/CreationPopupContent/resources';
import { Translation as GroupContent } from './KanbanCreator/GroupContent/resources';
import { Translation as KanbanCreator } from './KanbanCreator/resources';
import { Translation as ClockConfiguration } from './KanbanCreator/TagsCreation/ClockConfiguration/resources';
import { Translation as TagsCreation } from './KanbanCreator/TagsCreation/resources';
import { Translation as FilterOptionsArchived } from './KanbanHeader/Filters/Options/Archived/resources';
import { Translation as FilterOptionsButtons } from './KanbanHeader/Filters/Options/Buttons/resources';
import { Translation as FilterOptionsComments } from './KanbanHeader/Filters/Options/Comments/resources';
import { Translation as QuickFilters } from './KanbanHeader/Filters/Options/QuickFilters/resources';
import { Translation as Filters } from './KanbanHeader/Filters/resources';
import { Translation as KanbanHeaderEdit } from './KanbanHeader/KanbanHeaderEdit/resources';
import { Translation as KanbanPicker } from './KanbanHeader/KanbanPicker/resources';
import { Translation as KanbanHeader } from './KanbanHeader/resources';
import { Translation as KanbanBoard } from './resources';

export const Translation = {
	pl: {
		Kanban: {
			KanbanCreator: {
				tagGroup: 'Kanbanowa grupa tagów',
				nameSwimlineErrorMessage: '^Nazwa swimline powinna zawierać od 1 do 50 znaków!',
				createTagGroup: 'Stwórz nową grupę',
				tagsErrorMessage: '^Dodaj kolumny do kanbana!',
				rest: 'Pozostałe zadania',
			},
			KanbanContent: {
				ConfigureKanban: {
					back: 'Wstecz',
					deleteKanban: 'Usuń kanban',
					Mappings: {
						saveChanges: 'Zapisz zmiany',
						kanbanName: 'Nazwa kanbana',
						projects: 'Projekty',
						deleteProject1:
							'Jeśli wybrany formularz należy do projektu, który chcesz usunąć dokonane zmiany i mapowania',
						deleteProject2: 'Mapowania',
						deleteProject3: 'dla tego projektu zostaną bezpowrotnie usunięte! Kontynuować?',
						changesWontBeSave: 'Dokonane zmiany nie zostaną zapisane! Czy kontynuować?',
					},
					Swimlines: {
						saveChanges: 'Zapisz zmiany',

						swimlines: 'Konfiguracja swimline',
						subtext: 'Tutaj możesz dodawać i konfigurować swimliny',
					},
				},
				ColumnsKanbanContent: {
					Swimline: {
						SwimlineChart: {
							issues: 'Zgłoszenia',
							issuesInColumns: 'Zadania w kolumnach',
							noIssues: 'Brak zgłoszeń',
						},
						addIssue: 'Dodaj zgłoszenie',
						swimLineBasic: 'Pozostałe',
						TaskList: {
							InnerItem: { noMappedTag: 'Brak zmapowanego taga do kolmny' },
							chooseStatusToAssign: 'Wybierz status, który chcesz ustawić w zgłoszeniu',
							KanbanTile: {
								CardActionsContent: {
									creator: 'Twórca zadania',
									reporters: 'Osoby zgłaszające zadanie',
									assignees: 'Osoby wykonujące zadanie',
									observers: 'Obserwatorzy zadania',
									attachments: 'Załączniki',
									comments: 'Komentarze',
									info:
										'To zadanie ma w statusie tagi z wielu kolumn kanbana, dlatego występuje w kilku miejscach',
								},
								CardHeader: {
									new: 'Nowy',
								},
							},
						},
					},
					SwimlineConfiguration: {
						SwimlineConfigurationDrawer: {
							SwimlineTile: {
								creationDate: 'Data stworzenia',
								isDefaultSwimline: 'To jest defaultowy swimline. Do niego będą trafiać nowe zadania',
								defaultSwimline: 'Tego swimline nie możesz usunąć, jest to domyślny swimline',
							},
						},
						SwimlineCreator: {
							name: 'Nazwa',
							color: 'Kolor',

							cancel: 'Anuluj',
							create: 'Stwórz',
							validationMessageEmptyField: '^Pole nazwy nie może być puste',
							validationMessageToLong: '^Nazwa jest za długa!',
							validationMessageNoColor: 'Ustaw kolor!',
							validationMessageNoSuchKanban: '^Nie można znaleźć tego kanbana!',
						},
					},
				},
				EditKanbanContent: {
					InnerEditList: {
						KanbanFormSection: {
							KanbanIssueForm: {
								IssueFormSectionWithCollapse: {
									FieldDefinition: {
										MultiTag: {
											noMappedTagToColumn: 'Brak mapowania tagu statusowego do kolumny kanbana ',
										},
									},
								},
							},
						},
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanCreator: {
				tagGroup: 'Kanban tag group',

				nameSwimlineErrorMessage: '^The name of the swimline should be between 1 and 50 characters!',
				tagsErrorMessage: '^Add columns to kanban!',
				createTagGroup: 'Create new group',
				rest: 'Other tasks',
				addKanban: 'Create new kanban in kanban creator.',
			},
			KanbanContent: {
				ConfigureKanban: {
					back: 'Back',
					deleteKanban: 'Delete kanban',

					Mappings: {
						saveChanges: 'Save changes',
						kanbanName: 'Kanban name',
						changesWontBeSave: `Changes you've made won't be saved! Do You want to continue?`,

						save: 'Save',
					},
					Swimlines: {
						saveChanges: 'Save changes',

						swimlines: 'Swimlines',
						subtext: 'Here You can add and manage your swimlines',
					},
				},
				ColumnsKanbanContent: {
					save: 'Save',
					Swimline: {
						SwimlineChart: {
							issues: 'Issues',
							issuesInColumns: 'Issues in columns',
							noIssues: 'No issues',
						},
						addIssue: 'Add issue',
						swimLineBasic: 'Rest',
						TaskList: {
							InnerItem: { noMappedTag: 'There is no mapped tag to this column' },
							chooseStatusToAssign: 'Choose which status you want to assign',
							KanbanTile: {
								CardActionsContent: {
									creator: 'Issue creator',
									reporters: 'Issue reporters',
									assignees: 'Issue assignees',
									observers: 'Issue observers',
									attachments: 'Attachments',
									comments: 'Comments',
									info: `This issue has in status field more than one column tag, so it occurs in many places`,
								},
								CardHeader: {
									new: 'New',
								},
							},
						},
					},
					SwimlineConfiguration: {
						SwimlineConfigurationDrawer: {
							SwimlineTile: {
								creationDate: 'Creation date',
								isDefaultSwimline: 'This is default swimline. New issues will appear in here',
								defaultSwimline: `You can't delete this swimeline, it's default swimline`,
							},
						},
						SwimlineCreator: {
							name: 'Name',
							color: 'Color',
							save: 'Save',
							cancel: 'Cancel',
							create: 'Create',
							validationMessageEmptyField: '^Name field cant be empty',
							validationMessageToLong: '^Name is to long!',
							validationMessageNoColor: 'Set the color!',
							validationMessageNoSuchKanban: '^There is no such kanban!',
						},
					},
				},
				EditKanbanContent: {
					InnerEditList: {
						KanbanFormSection: {
							KanbanIssueForm: {
								IssueFormSectionWithCollapse: {
									FieldDefinition: {
										MultiTag: {
											noMappedTagToColumn: 'No mapped status tag to kanban column  ',
										},
									},
								},
							},
						},
					},
				},
			},
		},
	},
};

export const KanbanTranslationPL = [
	Filters.pl,
	FilterOptionsButtons.pl,
	FilterOptionsComments.pl,
	QuickFilters.pl,
	FilterOptionsArchived.pl,
	Translation.pl,
	SwimlineConfigurationDrawer.pl,
	SwimlineConfiguration.pl,
	ColumnsKanbanContentSwimlineConfiguration.pl,
	KanbanBoard.pl,
	AddColumn.pl,
	KanbanHeader.pl,
	KanbanPicker.pl,
	KanbanHeaderEdit.pl,
	KanbanCreator.pl,
	CreationPopupContent.pl,
	ColumnsKanbanContent.pl,
	TaskListHeader.pl,
	TagsCreation.pl,
	ClockConfiguration.pl,
	GroupContent.pl,
	SwimlineTile.pl,
	Actions.pl,
	Tabs.pl,
	MappingsTab.pl,
	LeftSideButtons.pl,
	InEditHeader.pl,
	MultiTag.pl,
	GeneralTab.pl,
	SwimlineCreationModal.pl,
];

export const KanbanTranslationEN = [
	Filters.en,
	FilterOptionsButtons.en,
	FilterOptionsComments.en,
	QuickFilters.en,
	FilterOptionsArchived.en,
	Translation.en,
	SwimlineConfigurationDrawer.en,
	SwimlineConfiguration.en,
	ColumnsKanbanContentSwimlineConfiguration.en,
	KanbanBoard.en,
	AddColumn.en,
	KanbanHeader.en,
	KanbanPicker.en,
	KanbanHeaderEdit.en,
	KanbanCreator.en,
	CreationPopupContent.en,
	ColumnsKanbanContent.en,
	TaskListHeader.en,
	TagsCreation.en,
	ClockConfiguration.en,
	GroupContent.en,
	SwimlineTile.en,
	Actions.en,
	Tabs.en,
	MappingsTab.en,
	LeftSideButtons.en,
	InEditHeader.en,
	MultiTag.en,
	GeneralTab.en,
	SwimlineCreationModal.en,
];
