import { Translation as Error } from 'utils/error/resources';

import { LangResources } from '@skillandchill/tasker-widgets2/dist/utils/language/languageResources';
import { pl_default as widgetResource } from '@skillandchill/tasker-widgets2/dist/utils/language/widgets.translation';
import translationReduced from '@skillandchill/translate/dist/pl/default.json';

import { DashboardTranslationPL } from '@/layouts/Dashboard/index.translation';
import { TranslationNumberToText } from '@/locales/additionalTranslations';
import { multipleMerge } from '@/locales/utils';
import { ActivitiesTranslationPL } from '@/views/Activities/index.translation';
import { ReckonTranslationPL } from '@/views/Agreement/index.translation';
import { DelegationsAcceptanceListTranslationPL } from '@/views/DelegationsAcceptanceList/index.translation';
import { DelegationsListTranslationPL } from '@/views/DelegationsList/index.translation';
import { IssueModalNewTranslationPL } from '@/views/IssueModelNew/index.translation';
import { RolesPL } from '@/views/Roles/index.translation';

import { BudgetsTranslationPL } from 'view/Budgets/BudgetsWidget/index.translation';
import { CalendarTranslationPL } from 'view/Calendar/index.translation';
import { CustomerCreateTranslationPL } from 'view/CustomerCreate/index.translation';
import { CustomerDetailTranslationPL } from 'view/CustomerDetail/index.translation';
import { CustomerListTranslationPL } from 'view/CustomerList/index.translation';
import { DictionariesTranslationPL } from 'view/Dictionaries/index.translation';
import { DictionaryContentTranslationPL } from 'view/DictionaryContent/index.translation';
import { Translation as Error401 } from 'view/Error401/resources';
import { Translation as Error404 } from 'view/Error404/resources';
import { Translation as Error500 } from 'view/Error500/resources';
import { GantTranslationPL } from 'view/Gant/index.translation';
import { HolidayTranslationPL } from 'view/Holiday/index.translation';
import { IssueListTranslationPL } from 'view/IssueList/index.translation';
import { KanbanTranslationPL } from 'view/KanbanBoard/index.translation';
import { KeyListenerTranslationPL } from 'view/KeyListener/index.translation';
import { Translation as KeyListener } from 'view/KeyListener/resources';
import { LeaveRequestCalendarPL } from 'view/LeaveRequestCalendar/index.translation';
import { LeaveTypeTranslationPL } from 'view/LeaveTypeList/index.translation';
import { Translation as Loading } from 'view/Loading/resources';
import { LogintranslationPL } from 'view/Login/index.translation';
import { NotificationListTranslationPL } from 'view/NotificationList/index.translation';
import { OrganizationPanelTranslationPL } from 'view/OrganizationPanel/index.translation';
import { PortalIssueTranslationPL } from 'view/PortalIssue/index.translation';
import { PortalListTranslationPL } from 'view/PortalList/index.translation';
import { ProjectCreateTranslationPL } from 'view/ProjectCreate/index.translation';
import { ProjectDetailsTranslationPL } from 'view/ProjectDetails/index.translation';
import { ProjectListTranslationPL } from 'view/ProjectList/index.translation';
import { Translation as RecoverPassword } from 'view/RecoverPassword/resources';
import { RegisterTranslationPL } from 'view/Register/index.translation';
import { ReportsTranslationPL } from 'view/Reports/index.translation';
import { Translation as ResetPassword } from 'view/ResetPassword/resources';
import { RolePanelTranslationPL } from 'view/RolePanel/index.translation';
import { SearchBarTranslationPL } from 'view/SearchBar/index.translation';
import { SettingsTranslationPL } from 'view/Settings/index.translation';
import { Translation as SnackBarProviderWrapper } from 'view/SnackBarProviderWrapper/resources';
import { TagAdminPanelTranslationPL } from 'view/TagAdminPanel/index.translation';
import { TagGroupInfoTranslationPL } from 'view/TagGroupInfo/index.translation';
import { TimeAssignTranslationPL } from 'view/TimeAssign/index.translation';
import { TimeReportTranslationPL } from 'view/TimeReport/index.translation';
import { Translation as UnsavedChangesAlert } from 'view/UnsavedChangesAlert/resources';
import { Translation as UnsavedCommentAlert } from 'view/UnsavedCommentAlert/resources';
import { VersionsTranslationPL } from 'view/Versions/index.translation';
import { WorkDayEndSummaryTranslationPL } from 'view/WorkDayEndSummary/index.translation';

const translation = multipleMerge([
	translationReduced.FRONTEND,
	LangResources.pl,
	Error404.pl,
	Error401.pl,
	Error500.pl,
	Loading.pl,
	RecoverPassword.pl,
	ResetPassword.pl,
	SnackBarProviderWrapper.pl,
	UnsavedChangesAlert.pl,
	UnsavedCommentAlert.pl,
	Error.pl,
	KeyListener.pl,
	TranslationNumberToText.pl,
	...DashboardTranslationPL,
	...ActivitiesTranslationPL,
	...VersionsTranslationPL,
	...CalendarTranslationPL,
	...CustomerCreateTranslationPL,
	...CustomerDetailTranslationPL,
	...CustomerListTranslationPL,
	...DictionariesTranslationPL,
	...DictionaryContentTranslationPL,
	...GantTranslationPL,
	...HolidayTranslationPL,
	...IssueListTranslationPL,
	...LogintranslationPL,
	...NotificationListTranslationPL,
	...OrganizationPanelTranslationPL,
	...PortalIssueTranslationPL,
	...PortalListTranslationPL,
	...ProjectCreateTranslationPL,
	...ProjectDetailsTranslationPL,
	...ProjectListTranslationPL,
	...RegisterTranslationPL,
	...RolePanelTranslationPL,
	...SearchBarTranslationPL,
	...SettingsTranslationPL,
	...TagAdminPanelTranslationPL,
	...TagGroupInfoTranslationPL,
	...TimeAssignTranslationPL,
	...TimeReportTranslationPL,
	...KanbanTranslationPL,
	...IssueModalNewTranslationPL,
	...ReckonTranslationPL,
	...KeyListenerTranslationPL,
	...ReportsTranslationPL,
	...VersionsTranslationPL,
	...WorkDayEndSummaryTranslationPL,
	...LeaveTypeTranslationPL,
	...LeaveRequestCalendarPL,
	...BudgetsTranslationPL,
	...DelegationsListTranslationPL,
	...DelegationsAcceptanceListTranslationPL,
	...RolesPL,
]);

const widgets = multipleMerge([translationReduced.WIDGETS, widgetResource.WIDGETS]);

export default {
	pl_default: {
		FRONTEND: translation,
		WIDGETS: widgets,
		BACKEND: translationReduced.BACKEND,
	},
};
