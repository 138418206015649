import {
	EOrganizationParameter,
	IDictionary,
	IOrganization,
	IOrganizationParameter,
	IOrganizationSetting,
	IOrganizationStructure,
	IPage,
	IPosition,
	IProjectIssueType,
	IRole,
	IStaticEntity,
	ITagGroup,
	ITaskerUser,
	ITaskerUserRole,
	IWorklogTimer,
} from '@skillandchill/tasker-types';
import {
	DeepPartial,
	ICategoryPosition,
	IDelegationAcceptanceSettings,
	IDelegationDictionary,
	IDelegationDictionaryGroup,
	IDelegationDictionaryItem,
	IDelegationDictionaryItemType,
	IDelegationField,
	IPasswordPolicy,
} from '@skillandchill/tasker-types/dist/types';
import { IDelegationToDictionaries } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/delegationToDictionaries';
import { IForm } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/form';
import { IDelegationToDictionariesGet } from '@skillandchill/tasker-types/dist/types/endPoints/delegationToDictionaries';
import { ICreateForm } from '@skillandchill/tasker-types/dist/types/endPoints/form';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';
import { getFirst } from '@/utils/utils';

import { IFormInEdit } from 'view/OrganizationPanel/TabsContent/EndWorkDay/Details/Form/New/Form/Edit/model';
import { EStructureTag } from 'view/OrganizationPanel/TabsContent/OrganizationStructure/resources';

export interface NewOrganizationStructureItem {
	type: EStructureTag;
	item: IOrganizationStructure | ICategoryPosition | IPosition;
}

export const State = {
	organization: {} as IOrganization,
	organizationClockData: [] as IWorklogTimer[],
	passwordPolicies: [] as IPasswordPolicy[],
	sharedPasswordPolicies: [] as IPasswordPolicy[],
	taskerUserValidation: [] as Partial<ITaskerUser>[],
	taskerUser: {} as Partial<ITaskerUser>,
	EndWorkDay: {
		List: {
			page: {
				Count: 0,
				Data: [],
			} as IPage<IForm[]>,
		},
		Form: {
			IssueFormName: '',
			IssueFormSection: [],
			FormName: '',
		} as ICreateForm,
		FormEdition: {} as IFormInEdit,
		TaskerUsers: [] as Partial<ITaskerUser>[],
	},
	Configuration: {
		GoogleDrive: {
			Settings: [] as IOrganizationParameter[],
		},
		Discord: {
			Settings: [] as IOrganizationParameter[],
		},
	},
	Delegation: {
		General: {
			TaskerUserParameters: [] as IOrganizationParameter[],
		},
		Visibility: {
			Configuration: [] as IDelegationField[],
		},
		Defaults: {
			Configuration: [] as IDelegationField[],
		},
		UsersRoles: {
			Data: [] as DeepPartial<ITaskerUser>[],
			Users: [] as Partial<ITaskerUser>[],
		},
		AcceptanceSettings: {
			Configuration: {} as IDelegationAcceptanceSettings,
		},
		CustomDictionariesConfiguration: {
			Page: {
				Data: [],
				Count: 0,
			} as IPage<IDelegationDictionary[]>,
			DetailsPage: {
				Data: [],
				Count: 0,
			} as IPage<(IDelegationDictionaryItem & { IsDefault?: boolean })[]>,
			ItemTypes: [] as IDelegationDictionaryItemType[],
		},
		DictionaryConfiguration: {
			Dictionaries: [] as IDictionary[],
			Data: {} as IDelegationToDictionaries,
			AttachmentDictionaries: [] as IDelegationDictionary[],
			TransportDictionaries: [] as IDelegationDictionary[],
			GroupData: {} as IDelegationDictionaryGroup,
		},
	},
	///////////OPTIONS///////////////////////////
	groupsNoMultiple: [] as ITagGroup[],
	projectIssueTypes: [] as IProjectIssueType[],
	taskerUsers: [] as Partial<ITaskerUser>[],
	taskerUsersCount: 0 as number,
	rolesOptions: [] as IRole[],
	userRoles: [] as IRole[],
	structure: {} as IOrganizationStructure,
	positionRoles: [] as IStaticEntity[],
	categoryPositions: [] as ICategoryPosition[],
	lastAddedItem: {} as NewOrganizationStructureItem,
	organizations: [] as Partial<IOrganization>[], //aggregated users in organization
	refresher: 0,
	Days: {
		setting: {} as IOrganizationSetting,
	},
	///////////OPTIONS///////////////////////////
};
export type IOrganizationPanelEditorState = typeof State;

const organizationPanelReducer = (state = State, action: ObjectAction): IOrganizationPanelEditorState => {
	switch (action?.type) {
		case actionTypes.GET_ORGANIZATION: {
			return {
				...state,
				organization: action.organization,
			};
		}
		case actionTypes.ORGANIZATION_FETCH_CLOCK_DATA: {
			return {
				...state,
				organizationClockData: action.organizationWorklogTimer,
			};
		}
		case actionTypes.PROJECT_ISSUE_TYPES_FETCH: {
			return {
				...state,
				projectIssueTypes: action.projectIssueTypes,
			};
		}
		case actionTypes.FETCH_PASSWORD_POLICIES:
		case actionTypes.UPDATE_PASSWORD_POLICY: {
			return {
				...state,
				passwordPolicies: action?.policies,
			};
		}
		case actionTypes.FETCH_SHARED_PASSWORD_POLICIES: {
			return {
				...state,
				sharedPasswordPolicies: action?.policies,
			};
		}
		case actionTypes.USERS_FETCH_PAGE: {
			return {
				...state,
				taskerUsers: action.taskerUsers.Data,
				taskerUsersCount: action.taskerUsers.Count,
			};
		}
		case actionTypes.FETCH_ALL_USERS: {
			return {
				...state,
				taskerUsers: action.taskerUsers ?? [],
				taskerUsersCount: (action.taskerUsers ?? [])?.length ?? 0,
			};
		}
		case actionTypes.USER_FETCH_VALIDATION_DATA: {
			return {
				...state,
				taskerUserValidation: action.taskerUsers,
			};
		}
		case actionTypes.USER_FETCH_ONE: {
			return {
				...state,
				taskerUser: action.taskerUser,
			};
		}
		case actionTypes.FETCH_ALL_ROLES_NEW: {
			return {
				...state,
				rolesOptions: action?.roles,
			};
		}
		case actionTypes.FETCH_ALL_ROLES_WITH_USER_ROLES:
		case actionTypes.RESET_ALL_ROLES_WITH_USER_ROLES: {
			return {
				...state,
				rolesOptions: action?.roles,
				userRoles: action?.rolesById?.map((x: ITaskerUserRole) => x?.Role),
				taskerUser: action.taskerUser,
			};
		}
		case actionTypes.FETCH_ALL_ROLES_BY_USER_NEW: {
			return {
				...state,
				userRoles: action?.rolesById?.map((x: ITaskerUserRole) => x?.Role),
			};
		}

		case actionTypes.POSITION_ROLES_FETCH: {
			return {
				...state,
				positionRoles: action?.positionRoles,
			};
		}
		case actionTypes.CATEGORY_POSITIONS_FETCH: {
			return {
				...state,
				categoryPositions: action?.categoryPositions,
			};
		}
		case actionTypes.ORGANIZATION_STRUCTURE_FETCH: {
			return {
				...state,
				structure: action?.organizationStructure,
			};
		}
		case actionTypes.ORGANIZATION_STRUCTURE_DATA_FETCH: {
			return {
				...state,
				structure: action?.data?.organizationStructure,
				categoryPositions: action?.data?.categoryPositions,
				positionRoles: action?.data?.positionRoles,
				taskerUsers: action?.data?.taskerUsers,
			};
		}
		case actionTypes.ORGANIZATION_STRUCTURE_CREATE: {
			const addStructure = (id: number, structure: IOrganizationStructure): IOrganizationStructure => {
				return {
					...structure,
					OrganizationStructureChildren:
						structure?.Id == id
							? [...(structure?.OrganizationStructureChildren ?? []), action?.organizationStructure]
							: structure.OrganizationStructureChildren?.map(map => addStructure(id, map)),
				};
			};
			return {
				...state,
				structure: !action?.organizationStructure?.OrganizationStructureParentId
					? action?.organizationStructure
					: addStructure(action?.organizationStructure?.OrganizationStructureParentId, state?.structure),
				lastAddedItem: { type: EStructureTag.structure, item: action?.organizationStructure },
			};
		}
		case actionTypes.ORGANIZATION_STRUCTURE_UPDATE: {
			const updateStructure = (id: number, structure: IOrganizationStructure): IOrganizationStructure => {
				return structure?.Id == id
					? {
							...action?.organizationStructure,
							Positions: structure?.Positions,
							OrganizationStructureChildren: structure?.OrganizationStructureChildren,
					  }
					: {
							...structure,
							OrganizationStructureChildren: structure?.OrganizationStructureChildren?.map(map =>
								updateStructure(id, map)
							),
					  };
			};
			return {
				...state,
				structure: updateStructure(action?.organizationStructure?.Id, state?.structure),
				lastAddedItem: { type: EStructureTag.structure, item: action?.organizationStructure },
			};
		}

		case actionTypes.ORGANIZATION_STRUCTURE_DELETE: {
			const removeStructure = (id: number, structure: IOrganizationStructure): IOrganizationStructure => {
				if (id == structure.Id) return {} as IOrganizationStructure;
				if (structure?.OrganizationStructureChildren?.length == 0) return structure;

				const foundMatchingItem = structure?.OrganizationStructureChildren?.some(
					filter => filter?.Id == action?.id
				);

				return {
					...structure,
					OrganizationStructureChildren: foundMatchingItem
						? structure?.OrganizationStructureChildren?.filter(filter => filter.Id != action?.id)
						: structure?.OrganizationStructureChildren?.map(map => removeStructure(id, map)),
				};
			};
			return {
				...state,
				structure: removeStructure(action?.id, state?.structure),
			};
		}

		case actionTypes.POSITION_CREATE: {
			const addPosition = (id: number, structure: IOrganizationStructure): IOrganizationStructure => {
				return {
					...structure,
					Positions:
						structure?.Id == id
							? [action?.position, ...(structure?.Positions ?? [])]
							: [...(structure?.Positions ?? [])],
					OrganizationStructureChildren: structure.OrganizationStructureChildren?.map(map =>
						addPosition(id, map)
					),
				};
			};
			return {
				...state,
				structure: addPosition(action?.position?.OrganizationStructureId, state?.structure),
				lastAddedItem: { type: EStructureTag.position, item: action?.position },
			};
		}

		case actionTypes.POSITION_UPDATE: {
			const updatePosition = (id: number, structure: IOrganizationStructure): IOrganizationStructure => {
				let isUpdated = false;
				return {
					...structure,
					Positions: structure?.Positions?.map(position => {
						if (position.Id == id) {
							isUpdated = true;
							return action?.position;
						} else return position;
					}),
					OrganizationStructureChildren: isUpdated
						? structure.OrganizationStructureChildren
						: structure.OrganizationStructureChildren?.map(map => updatePosition(id, map)),
				};
			};
			return {
				...state,
				structure: updatePosition(action?.position?.Id, state?.structure),
			};
		}

		case actionTypes.POSITION_DELETE: {
			const removePosition = (id: number, structure: IOrganizationStructure): IOrganizationStructure => {
				if (structure?.Positions?.length == 0 && structure?.OrganizationStructureChildren?.length == 0)
					return structure;

				const foundMatchingItem = structure?.Positions?.some(filter => filter.Id == action?.id);

				return {
					...structure,
					Positions: foundMatchingItem
						? structure?.Positions?.filter(filter => filter.Id != action?.id)
						: structure?.Positions,
					OrganizationStructureChildren: foundMatchingItem
						? structure?.OrganizationStructureChildren
						: structure?.OrganizationStructureChildren?.map(map => removePosition(id, map)),
				};
			};
			return {
				...state,
				structure: removePosition(action?.id, state?.structure),
			};
		}

		case actionTypes.CATEGORY_POSITIONS_CREATE: {
			let didChange = false;
			const addPosition = (categoryPositions: ICategoryPosition[]): ICategoryPosition[] => {
				return (
					categoryPositions?.map(map => {
						if (map?.Id == action?.categoryPosition?.CategoryPositionId) {
							didChange = true;
							return {
								...map,
								CategoryPositions: [...(map?.CategoryPositions ?? []), action?.categoryPosition],
							};
						} else if (!didChange) {
							return {
								...map,
								CategoryPositions: addPosition(map?.CategoryPositions ?? []),
							};
						} else return map;
					}) ?? []
				);
			};
			return {
				...state,
				categoryPositions: !action?.categoryPosition?.CategoryPositionId
					? [...state?.categoryPositions, action?.categoryPosition]
					: addPosition(state?.categoryPositions),
				lastAddedItem: { type: EStructureTag.category, item: action?.categoryPosition },
			};
		}

		case actionTypes.CATEGORY_POSITIONS_UPDATE: {
			let didChange = false;
			const updatePosition = (categoryPositions: ICategoryPosition[]): ICategoryPosition[] => {
				return (
					categoryPositions?.map(map => {
						if (map?.Id == action?.categoryPosition?.Id) {
							didChange = true;
							return {
								...action?.categoryPosition,
							};
						} else if (!didChange) {
							return {
								...map,
								CategoryPositions: updatePosition(map?.CategoryPositions ?? []),
							};
						} else return map;
					}) ?? []
				);
			};
			return {
				...state,
				categoryPositions: updatePosition(state?.categoryPositions),
			};
		}

		case actionTypes.CATEGORY_POSITIONS_DELETE: {
			const removeCategoryPosition = (
				id: number,
				categoryPositions: ICategoryPosition[]
			): ICategoryPosition[] => {
				if (categoryPositions.length == 0) return [];

				const foundMatchingItem = categoryPositions?.some(filter => filter?.Id == action?.id);

				return [
					...(foundMatchingItem
						? categoryPositions?.filter(filter => filter.Id != action?.id)
						: categoryPositions?.map(map => ({
								...map,
								CategoryPositions: removeCategoryPosition(id, map?.CategoryPositions ?? []),
						  }))),
				];
			};
			return {
				...state,
				categoryPositions: removeCategoryPosition(action?.id, state?.categoryPositions),
			};
		}
		case actionTypes.SET_FORM_CREATE: {
			return {
				...state,
				EndWorkDay: {
					...state?.EndWorkDay,
					Form: action.data?.data,
					FormEdition: {
						...state?.EndWorkDay?.FormEdition,
						DiscordMessage: action.data?.data?.DiscordMessage,
					},
				},
			};
		}
		case actionTypes.SET_FORM_UPDATE: {
			return {
				...state,
				EndWorkDay: {
					...state?.EndWorkDay,
					FormEdition: action.data,
				},
			};
		}
		case actionTypes.FORM_DELETE_PAGE:
		case actionTypes.FORM_CREATE_PAGE:
		case actionTypes.FORM_UPDATE_PAGE:
		case actionTypes.FORM_FETCH_PAGE: {
			return {
				...state,
				EndWorkDay: {
					...state?.EndWorkDay,
					List: { page: action.data },
				},
			};
		}
		case actionTypes.FORM_FETCH: {
			const data: IForm = action?.data;

			return {
				...state,
				EndWorkDay: {
					...state?.EndWorkDay,
					FormEdition: data,
				},
			};
		}
		case actionTypes.SET_FORM_CREATE_TASKER_USERS: {
			return {
				...state,
				EndWorkDay: {
					...state?.EndWorkDay,
					Form: {
						...state?.EndWorkDay?.Form,
						TaskerUsers: action?.data,
					},
				},
			};
		}
		case actionTypes.SET_FORM_BODY_UPDATE: {
			return {
				...state,
				EndWorkDay: {
					...state?.EndWorkDay,
				},
			};
		}
		case actionTypes.SET_FORM_URL_UPDATE: {
			return {
				...state,
				EndWorkDay: {
					...state?.EndWorkDay,
				},
			};
		}
		case actionTypes.SET_FORM_URL_CREATE: {
			return {
				...state,
				EndWorkDay: {
					...state?.EndWorkDay,
					Form: {
						...state?.EndWorkDay?.Form,
					},
				},
			};
		}
		case actionTypes.SET_FORM_UPDATE_TASKER_USERS: {
			return {
				...state,
				EndWorkDay: {
					...state?.EndWorkDay,
					TaskerUsers: action?.data,
				},
			};
		}
		case actionTypes.RESET_CHECKLIST: {
			return {
				...state,
				EndWorkDay: {
					...State?.EndWorkDay,
					List: state?.EndWorkDay?.List,
				},
			};
		}
		case actionTypes.GET_GOOGLE_DRIVE: {
			return {
				...state,
				Configuration: {
					...state?.Configuration,
					GoogleDrive: {
						...state?.Configuration?.GoogleDrive,
						Settings: action?.data,
					},
				},
			};
		}
		case actionTypes.USER_REMOVE_FROM_ORGANIZATION:
		case actionTypes.TASKER_USER_ALL: {
			return {
				...state,
				organizations: action?.data,
				refresher: state?.refresher + 1,
			};
		}
		case actionTypes.TENANT_ADD_ADDRESS: {
			return {
				...state,
				organization: action?.data,
			};
		}
		case actionTypes.UPDATE_INVOICE_DETAIL: {
			return {
				...state,
				organization: {
					...state?.organization,
					InvoiceDetail: action?.data,
				},
			};
		}
		case actionTypes.DELEGATION_VISIBILITY_CONFIGURATION_FETCH: {
			return {
				...state,
				Delegation: {
					...state?.Delegation,
					Visibility: {
						...state?.Delegation?.Visibility,
						Configuration: action?.data,
					},
				},
			};
		}
		case actionTypes.DELEGATION_DEFAULTS_CONFIGURATION_FETCH: {
			return {
				...state,
				Delegation: {
					...state?.Delegation,
					Defaults: {
						...state?.Delegation?.Defaults,
						Configuration: action?.data,
					},
				},
			};
		}
		case actionTypes.DELEGATION_DEFAULTS_CONFIGURATION_MANAGE: {
			let filteredVisibilityConfig;
			if (action?.data?.ValueBoolean === undefined) {
				filteredVisibilityConfig = state?.Delegation?.Visibility?.Configuration?.filter(
					delegationField => delegationField?.Id === action?.data?.DelegationFieldId
				);
			} else {
				filteredVisibilityConfig = state?.Delegation?.Visibility?.Configuration;
			}

			return {
				...state,
				Delegation: {
					...state?.Delegation,
					Visibility: {
						Configuration: filteredVisibilityConfig,
					},
				},
			};
		}
		case actionTypes.DELEGATION_USERS_ROLES_FETCH:
		case actionTypes.DELEGATION_USERS_ROLES_MANAGE:
		case actionTypes.DELEGATION_USERS_ROLES_DELETE: {
			return {
				...state,
				Delegation: {
					...state?.Delegation,
					UsersRoles: {
						Data: action?.data?.delegationRoleToTaskerUser,
						Users: action?.data?.users,
					},
				},
			};
		}
		case actionTypes.DELEGATION_ACCEPTANCE_SETTINGS_FETCH: {
			return {
				...state,
				Delegation: {
					...state?.Delegation,
					AcceptanceSettings: {
						Configuration: action?.data,
					},
				},
			};
		}
		case actionTypes.DELEGATION_TO_DICTIONARIES_FETCH_DATA: {
			const data: IDelegationToDictionariesGet = action?.data;
			return {
				...state,
				Delegation: {
					...state?.Delegation,
					DictionaryConfiguration: {
						...state?.Delegation?.DictionaryConfiguration,
						Data: data?.DelegationToDictionary,
						Dictionaries: data?.Dictionary,
					},
				},
			};
		}
		case actionTypes.DELEGATION_SET_CUSTOM_DICTIONARIES:
		case actionTypes.DELEGATION_FETCH_CUSTOM_DICTIONARIES:
		case actionTypes.DELEGATION_CREATE_CUSTOM_DICTIONARY:
		case actionTypes.DELEGATION_UPDATE_CUSTOM_DICTIONARY:
		case actionTypes.DELEGATION_REMOVE_CUSTOM_DICTIONARY: {
			return {
				...state,
				Delegation: {
					...state?.Delegation,
					CustomDictionariesConfiguration: {
						...state?.Delegation?.CustomDictionariesConfiguration,
						Page: action?.data,
					},
				},
			};
		}
		case actionTypes.DELEGATION_SET_CUSTOM_DICTIONARY_ITEMS:
		case actionTypes.DELEGATION_CREATE_CUSTOM_DICTIONARY_ITEM:
		case actionTypes.DELEGATION_UPDATE_CUSTOM_DICTIONARY_ITEM:
		case actionTypes.DELEGATION_REMOVE_CUSTOM_DICTIONARY_ITEM: {
			return {
				...state,
				Delegation: {
					...state?.Delegation,
					CustomDictionariesConfiguration: {
						...state?.Delegation?.CustomDictionariesConfiguration,
						DetailsPage: action?.data,
					},
				},
			};
		}
		case actionTypes.DELEGATION_FETCH_CUSTOM_DICTIONARY_ITEMS: {
			const transportSetting = state?.Delegation?.General?.TaskerUserParameters?.find(
				x => x?.Id === EOrganizationParameter.DelegationDefaultTransport
			);
			return {
				...state,
				Delegation: {
					...state?.Delegation,
					CustomDictionariesConfiguration: {
						...state?.Delegation?.CustomDictionariesConfiguration,
						DetailsPage: {
							Data: action?.data?.Data?.map((x: IDelegationDictionaryItem) => ({
								...x,
								IsDefault: getFirst(transportSetting?.OrganizationSetting ?? [])?.ValueNumber === x?.Id,
							})),
							Count: action?.data?.Count,
						},
					},
				},
			};
		}
		case actionTypes.DELEGATION_FETCH_CUSTOM_DICTIONARY_ITEM_TYPES: {
			return {
				...state,
				Delegation: {
					...state?.Delegation,
					CustomDictionariesConfiguration: {
						...state?.Delegation?.CustomDictionariesConfiguration,
						ItemTypes: action?.data,
					},
				},
			};
		}
		case actionTypes.DELEGATION_FETCH_ALL_ATTACHMENT_DICTIONARIES: {
			return {
				...state,
				Delegation: {
					...state?.Delegation,
					DictionaryConfiguration: {
						...state?.Delegation?.DictionaryConfiguration,
						AttachmentDictionaries: action?.data,
					},
				},
			};
		}
		case actionTypes.DELEGATION_FETCH_ALL_TRANSPORT_DICTIONARIES: {
			return {
				...state,
				Delegation: {
					...state?.Delegation,
					DictionaryConfiguration: {
						...state?.Delegation?.DictionaryConfiguration,
						TransportDictionaries: action?.data,
					},
				},
			};
		}
		case actionTypes.DELEGATION_DICTIONARY_GROUP_FETCH:
		case actionTypes.DELEGATION_DICTIONARY_GROUP_CREATE:
		case actionTypes.DELEGATION_DICTIONARY_GROUP_UPDATE: {
			return {
				...state,
				Delegation: {
					...state?.Delegation,
					DictionaryConfiguration: {
						...state?.Delegation?.DictionaryConfiguration,
						GroupData: action?.data?.[0],
					},
				},
			};
		}
		case actionTypes.NEED_REFRESH: {
			return {
				...state,
				refresher: state?.refresher + 1,
			};
		}
		case actionTypes.EDIT_ORGANIZATION_SETTING: {
			const transportSetting: IOrganizationSetting | undefined = action?.data?.find(
				(x: IOrganizationSetting) =>
					x?.OrganizationParameterId === EOrganizationParameter.DelegationDefaultTransport
			);
			return {
				...state,
				Days: {
					...state?.Days,
					setting: action?.data?.find(
						(x: IOrganizationSetting) =>
							x?.OrganizationParameterId === EOrganizationParameter.AcceptanceExceptionDuration
					),
				},
				Delegation: {
					...state?.Delegation,
					CustomDictionariesConfiguration: {
						...state?.Delegation?.CustomDictionariesConfiguration,
						DetailsPage: {
							...state?.Delegation?.CustomDictionariesConfiguration?.DetailsPage,
							Data: state?.Delegation?.CustomDictionariesConfiguration?.DetailsPage?.Data?.map(
								(x: IDelegationDictionaryItem) => ({
									...x,
									IsDefault: transportSetting?.ValueNumber === x?.Id,
								})
							),
						},
					},
				},
			};
		}
		case actionTypes.GET_ORGANIZATION_SETTING: {
			return {
				...state,
				Days: {
					...state?.Days,
					setting: action?.data?.find(
						(x: IOrganizationSetting) =>
							x?.OrganizationParameterId === EOrganizationParameter.AcceptanceExceptionDuration
					),
				},
			};
		}
		case actionTypes.GET_ORGANIZATION_SETTING_FOR_DELEGATION: {
			return {
				...state,
				Delegation: {
					...state?.Delegation,
					General: {
						...state?.Delegation?.General,
						TaskerUserParameters: action?.data,
					},
				},
			};
		}
		case actionTypes.GET_DISCORD: {
			return {
				...state,
				Configuration: {
					...state?.Configuration,
					Discord: {
						Settings: action?.data?.filter(
							(x: IOrganizationParameter) =>
								x?.Id === EOrganizationParameter.Discord ||
								x?.Id === EOrganizationParameter.DiscordConfigured
						),
					},
				},
			};
		}
		default: {
			return state;
		}
	}
};

export default organizationPanelReducer;
