import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		root: {
			justifyContent: 'stretch',
			marginBottom: theme.spacing(2),
		},
		editTitle: {
			padding: theme.spacing(2),
		},
		comment: {
			'& + &': {
				marginTop: theme.spacing(1),
			},
		},
		addCommentField: {
			flex: 1,
		},
		send: {
			background: 'lightGreen',
			'&:hover': {
				background: 'green',
				color: 'white',
			},
		},
		commentTileMargin: {
			marginBottom: theme.spacing(2),
		},
		checkBox: {
			marginLeft: theme.spacing(1),
		},
		richEditor: {
			scrollbarWidth: 'none',
			height: theme.spacing(37.5),
			minWidth: '500px',
		},

		whiteBackground: {
			borderRadius: theme.spacing(0.625),
			backgroundColor: '#fff',
		},
		button: {
			margin: theme.spacing(0.25),
		},

		commentsContainer: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(2),

			overflowY: 'scroll',
			borderRadius: '10px',
			padding: theme.spacing(0),
			wordBreak: 'break-all',
			width: '100%',
			maxHeight: '400px',
			overflowX: 'hidden',
		},

		noComments: {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
		},
	}),
	{ name: `IssueModalEditor_Comments` }
);
