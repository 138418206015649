import clsx from 'clsx';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Typography } from '@mui/material';

import bytesToSize from '@skillandchill/tasker-widgets2/dist/utils/bytesToSize';

import { useTrans } from '@/utils/hooks/useTrans';

import { EDropzonePlacement } from '../model';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const DropzoneInfo = (props: Props): JSX.Element => {
	const {
		allowedFiletypes,
		maxFilesize,
		classes: classesInput,
		dropZonePlacement = EDropzonePlacement.Bottom,
	} = props;

	const classes = useStyles();
	const { t } = useTrans(
		'DelegationList.DelegationModal.DelegationModalContent.DelegationDetails.DelegationAttachments.Dropzone.DropzoneInfo'
	);

	const getClassName = (): string => {
		let returnValue = `${classes.dropzoneInfo}`;

		if (classesInput?.dropZoneArea) returnValue += ` ${classesInput.dropZoneArea}`;
		if (dropZonePlacement === EDropzonePlacement.Top) returnValue += ` ${classes.top}`;
		else returnValue += ` ${classes.bottom}`;

		return returnValue;
	};

	return (
		<div className={getClassName()}>
			<UploadFileIcon
				className={
					classesInput?.dropZoneAreaIcon
						? clsx(classes.dropzoneInfoIcon, classesInput.dropZoneAreaIcon)
						: classes.dropzoneInfoIcon
				}
			/>
			<Typography variant={Resources.GlobalResources.body1} className={classes.dropzoneInfoText}>
				<strong>{t('callToAction1')}</strong> {t('callToAction2')}
			</Typography>
			<Typography variant={Resources.GlobalResources.body2} className={classes.dropzoneInfoText}>
				{t('allowedExtensions')} {allowedFiletypes.Extensions.join(', ')} ({Resources.max}{' '}
				{bytesToSize(maxFilesize)})
			</Typography>
		</div>
	);
};
